<template>
  <div class="pohone-top">
    <div class="left">
      <div class="menu"></div>
      <div class="menu"></div>
      <div class="menu"></div>
    </div>
    <img src="../../assets/img/phone/top.png" alt="" class="logo"/>
    <img src="../../assets/img/search.png" alt="" class="search"/>
  </div>
</template>
<script>
export default {
  name: "PhoneHeader",
};
</script>
<style lang="scss" scoped>
.pohone-top {
  width: 100%;
  height: 100px;
  position: absolute;
  background: transparent;
  z-index: 9;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .logo{
    width: 291px;
    height: 59px;
  }
  .search{
    width: 32px;
    height: 32px;
  }
  .left {
    display: flex;
    flex-direction: column;
    gap: 6px;
    .menu {
      width: 40px;
      height: 4px;
      background: #ffffff;
    }
  }
}
</style>
