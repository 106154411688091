<template>
  <div class="phone-footer">
    <div class="menu">
      <div>招生</div>
      <div class="line"></div>
      <div>就业</div>
      <div class="line"></div>
      <div>招聘</div>
      <div class="line"></div>
      <div>校友网</div>
      <div class="line"></div>
      <div>校园服务</div>
    </div>
    <div class="accounts">
      <img src="../../assets/img/phone/weixin.png" alt="" />
      <img src="../../assets/img/phone/weibo.png" alt="" />
      <img src="../../assets/img/phone/douyin.png" alt="" />
    </div>
    <div class="lineLone"></div>
    <div class="msg">
      <div>校长信箱:president@bisu.edu.cn 纪检监察举报</div>
      <div>北京第二外国语学院 版权所有</div>
      <div>京ICP备：05067963号</div>
      <div>
        文保网安备案号：110402430076 Copyright 2001-2010 B.I.S.U. all rights
        reserved
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PhoneFooter",
};
</script>
<style lang="scss" scoped>
.phone-footer {
  padding: 59px 67px;
  background: #b32924;
  .menu {
    //font-family: PingFang;
    font-weight: 400;
    font-size: 26px;
    color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .line {
      width: 1px;
      height: 23px;
      border: 1px solid #ffffff;
    }
  }
  .accounts {
    display: flex;
    justify-content: center;
    margin-top: 56px;
    gap: 50px;
    .img {
      width: 70px;
      height: 70px;
    }
  }
  .lineLone {
    width: auto;
    height: 1px;
    border: 1px solid #ffffff;
    opacity: 0.3;
    margin-top: 56px;
  }
  .msg {
    margin-top: 56px;
    //font-family: PingFang;
    font-weight: 400;
    font-size: 20px;
    color: #ffffff;
    line-height: 36px;
    text-align: center;
  }
}
</style>
