<template>
  <div class="bottom">
    <img class="city" src="../assets/img/bottom.png" alt="" />
    <!--    <div class="bottom_header">
      <img class="iconimg" src="../assets/img/second/2012300126354659905.png" alt="" />
      <div class="names">
        &nbsp;&nbsp;文化与传播学院
      </div>
    </div>-->
    <div class="bottom_content w1364">
      <!--      <div class="nav">-->
      <!--        <span>关于研究院</span>-->
      <!--        <span>beat365亚洲官方网站</span>-->
      <!--        <span>科研团队</span>-->
      <!--        <span>高端学术论坛</span>-->
      <!--        <span>科学研究</span>-->
      <!--        <span>学术成果</span>-->
      <!--        <span>学术动态</span>-->
      <!--        <span>国际合作</span>-->
      <!--      </div>-->
      <div class="navDiv" v-if="hasLink">
      

        <!-- <dl class="bottom_links" v-if="college === 'whycbxy'">
          <dd>
            <p @click="go('https://bkzs.bisu.edu.cn/')">本科生招生</p>
            <p @click="go('https://yz.bisu.edu.cn/')">研究生招生</p>
            <p @click="go('http://jiuye.bisu.edu.cn/')">就业</p>
            <p @click="go('https://www.bisu.edu.cn/col/col18960/index.html')">
              招聘
            </p>
            <p @click="go('http://bisuer.bisu.edu.cn/')">校友网</p>
            <p @click="go('http://edf.bisu.edu.cn/')">教育发展基金会</p>
          </dd>
        </dl> -->
        <dl class="bottom_links" >
          <dd>
            <p
              v-for="data in bottomArr"
              :key="data.name"
              @click="go(data.link)"
            >
              {{ data.name }}
            </p>
          </dd>
        </dl>
        <!--        <dl class="bottom_weChat">-->
        <!--          <dt>-->
        <!--            <img src="../assets/img/1699427834294.jpg" alt="" style="width: 6vw" />-->
        <!--          </dt>-->
        <!--          <dd>关注微信公众号</dd>-->
        <!--        </dl>-->
        <ul class="lj-div2" v-if="hasQrCode && college === 'whycbxy'">
          <li>
            <a class="wx"
              >
              <!-- <img
                @mouseenter="mouseenter(1)"
                @mouseleave="mouseleave"
                src="../assets/img/wechat.png"
              /> -->
              <img src="./weixin.png" alt="">
              <p>微信公众号</p>
            </a>
          </li>
          <!-- <li>
            <a class="wb"
              >
          
              <img src="./weibo.png" alt="">
              <p>微博</p>
            </a>
          </li> -->
          <li>
            <a class="dy"
              >
              <!-- <img
                @mouseenter="mouseenter(3)"
                @mouseleave="mouseleave"
                src="../assets/img/douyin.png"
              /> -->
              <img src="./douyin.png" alt="">
              <p>抖音</p>

            </a>
          </li>
          <div class="ew_img">
            <img v-if="ewImgIndex == 1" class="wx_img" src="./weixin.png" />
            <img v-if="ewImgIndex == 2" class="wb_img" src="./weibo.png" />
            <img v-if="ewImgIndex == 3" class="dy_img" src="./douyin.png" />
          </div>
        </ul>
        <ul class="lj-div2" v-else>
          <li v-if="qrCodeArr.includes('weixin')">
            <a class="wx"
              >
              <!-- <img
                @mouseenter="mouseenter1('weixin')"
                @mouseleave="mouseleave1"
                src="../assets/img/wechat.png"
              /> -->
              <img
           
                :src="require(`./img/weixin-${this.college}.png`)"
              />
              <p>{{this.college === 'ryxy' ? "北二外日语":"微信公众号"}}</p>
            </a>
          </li>
          <li v-if="qrCodeArr.includes('weixin1')">
            <a class="wx"
              >
              <!-- <img
                @mouseenter="mouseenter1('weixin1')"
                @mouseleave="mouseleave1"
                src="../assets/img/wechat.png"
              /> -->
              <img :src="require(`./img/weixin1-${this.college}.png`)"/>
              <p>{{this.college === 'ryxy' ? "国际日本研究中心":"微信公众号"}}</p>
            </a>
          </li>
          <li v-if="qrCodeArr.includes('weibo')">
            <a class="wb"
              >
              <!-- <img
                @mouseenter="mouseenter1('weibo')"
                @mouseleave="mouseleave1"
                src="../assets/img/weibo.png"
              /> -->
              <img :src="require(`./img/weibo-${this.college}.png`)"/>
              <p>微博</p>
            </a>
          </li>
          <li v-if="qrCodeArr.includes('douyin')">
            <a class="dy"
              >
              <!-- <img
                @mouseenter="mouseenter1('douyin')"
                @mouseleave="mouseleave1"
                src="../assets/img/douyin.png"
              /> -->
              <img :src="require(`./img/douyin-${this.college}.png`)"/>
              <p>抖音</p>
            </a>
          </li>
          <div class="ew_img" :style="{ left: getIndex() * 115 - 60 + 'px' }">
            <img v-if="imgUrl" class="wx_img" :src="imgUrl" />
          </div>
        </ul>
      </div>
    </div>
    <div class="bottom_maskBg">
      <div class="bottom_mask w1364">
        <span>
          北京第二外国语学院 © 版权所有 京ICP备:05067963号
          文保网安备案号:110402430076    Copyright 2001-2010 B.I.S.U. all rights
          reserved
        </span>
        <img class="bottoms" src="../assets/img/bottom.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { allCollegeInfo } from "../allCollegeInfo";

export default {
  data() {
    return {
      ewImgIndex: 0,
      college: this.$store.state.college,
      hasLink: false,
      hasQrCode: false,
      bottomArr: [],
      qrCodeArr: [],
      imgs: [],
      imgUrl: "",
      str: "",
    };
  },
  created() {
    this.hasLink =
      this.college === "whycbxy"
        ? true
        : allCollegeInfo(this)[this.college].footer.hasLink;
    this.hasQrCode =
      this.college === "whycbxy"
        ? true
        : allCollegeInfo(this)[this.college].footer.hasQrCode;
    if (this.hasLink) {
      this.bottomArr =
        allCollegeInfo(this)[this.college].footer.bottomArr || [];
      this.qrCodeArr =
        allCollegeInfo(this)[this.college].footer.qrCodeArr || [];
    }
  },
  methods: {
    mouseenter(index) {
      this.ewImgIndex = index;
    },
    mouseleave() {
      this.ewImgIndex = 0;
    },
    mouseenter1(str) {
      this.str = str;
      this.imgUrl = require(`./img/${str}-${this.college}.png`);
    },
    mouseleave1() {
      this.str = "";
      this.imgUrl = null;
    },
    go(url) {
      window.open(url, "_blank");
    },
    getIndex(str) {
      return this.qrCodeArr.indexOf(this.str);
    },
  },
};
</script>

<style lang="scss" scoped>
.city {
  width: 100%;
  background: #fff;
}
.bottom {
  width: 100%;
  position: relative;
  color: #fff;
  background: #a12916;
  overflow: hidden;
}
.bottom_header {
  width: 100%;
  height: 152px;
  display: flex;
  position: relative;
  border-bottom: 1px solid rgba(250, 225, 208, 0.4);
  align-items: center;
  padding-left: 267px;
  .iconimg {
    width: 323px;
  }
  .names {
    border-left: 1px solid rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    padding-left: 18px;
    height: 45px;
    font-weight: 400;
    font-size: 22px;
    color: #ebebeb;
    margin-left: 20px;
  }
}
.bottom_content {
  // padding: 0 266px 0 273px;
  position: relative;
  .nav {
    font-weight: 400;
    font-size: 18px;
    color: #ebebeb;
    padding-top: 50px;
    padding-bottom: 50px;
    > span {
      margin-right: 32px;
    }
  }
  .navDiv {
    //margin-top: 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    align-items: center;
    .bottom_links {
      width: 880px;
      margin-top: 22px;
      min-height: 100px;
      font-weight: 400;
      font-size: 18px;
      color: #ebebeb;
      margin-bottom: 40px;
      //height: 244px;
    }
    .bottom_links dd {
      margin-top: 34px;
    }
    .bottom_links dd p {
      padding-left: 10px;
      width: 272px;
      height: 61px;
      border: 1px solid rgba(246, 246, 246, 0.4);
      line-height: 61px;
      float: left;
      margin: 0 15px 13px 0;
      cursor: pointer;
      font-weight: 400;
      font-size: 18px;
      color: rgba(235, 235, 235, 0.6);
    }
    .bottom_weChat {
      text-align: center;
      width: 147px;
      height: 147px;
      border-radius: 5px;
      padding: 11px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      position: relative;
      top: -40px;
    }
    .bottom_weChat dt {
      width: 126px;
      height: 126px;
      box-sizing: content-box;
    }
    .bottom_weChat dt img {
      width: 126px;
      height: 126px;
    }
    .bottom_weChat dd {
      margin-top: 45px;
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
    }
  }
}
.bottom_maskBg {
  background: #952210;
}
.bottom_mask {
  justify-content: space-between;
  width: 100%;
  height: 102px;
  // position: absolute;
  // padding: 0 269px;
  display: flex;
  align-items: center;
  //bottom: 0;
}
.bottom_mask span {
  font-weight: 400;
  font-size: 16px;
  color: #ebebeb;
  opacity: 0.5;
}
.bottom_mask img {
  width: 80px;
  height: 80px;
}
.lj-div2 {
  height: 150px;
  display: flex;
  // position: absolute;
  right: 50px;
  top: 10px;
}
.ew_img {
  position: absolute;
  top: -150px;
  left: 58px;
  width: 180px;
  height: 180px;
  img {
    width: 100%;
  }
}
.lj-div2 li {
  width: 100px;
  text-align: center;
  padding-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  img {
    width: 100px;
    height: 100px;
  }
  p{
    margin-top: 5px;
  }
}

</style>
