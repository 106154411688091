import { get, post, myRequest } from "../httpIntercept";
const baseUrl = process.env.NODE_ENV === "development" ? "/api" : "api/";
const baseUrl_site = process.env.NODE_ENV === "development" ? "" : "";
// const webName = process.env.NODE_ENV === "development" ? "whycbxy" : "whycbxy"; //erwai
const webName = process.env.VUE_APP_COLLEGE;
export default {
  getSearch(data) {
    let formData = new FormData();
    for (let key in data) {
      let ele = data[key];
      formData.append(key, ele);
    }
    return post(
      `${baseUrl_site}site/${webName}/story/signatures/list`,
      formData
    );
  },
  //获取轮播图s
  getlistMenuHome(data) {
    return post(baseUrl + "home/news/listMenuHome", data);
  },
  //获取首页轮播图
  getHomePage(data) {
    return post(baseUrl + "home/getHomePage", data);
  },
  // 获取栏目树
  getTreeData() {
    return get(baseUrl + "column/getColumnTree");
  },
  getlist(params) {
    return myRequest(
      `${baseUrl_site}site/${webName}/story/signatures/list`,
      params
    );
  },
  //获取栏目下的稿件列表
  getSignaturesList(params) {
    return myRequest(
      `${baseUrl_site}site/${webName}/story/signatures/list`,
      params
    );
  },
  // 获取单篇稿件
  getStory(id) {
    return get(`${baseUrl_site}site/${webName}/story/${id}/get`);
  },
  // 信息公开列表
  getPubMessageTree() {
    return get(baseUrl + "pubMessage/getPubMessageTree");
  },
  getChannels() {
    return get(`${baseUrl_site}site/channels`);
  },
  getAllColumn(params) {
    return get(
      `${baseUrl_site}site/${params.channelId}/column/listAll?mode=tree`
    );
  },
  //获取专题列表
  getZTList() {
    return get(`${baseUrl_site}site/${webName}/column/listAll?mode=us`);
  },
  getNewsDetailData(params) {
    return get("api/sprint/getDetails", params);
  },
  getNewsList(params) {
    return get("newNews/getArticleByAppInfoIdOrderInteractiveNum", {
      params: params,
    });
  },
  getSearchList(params) {
    return post("search/searchUserSprint", params);
  },
  //反馈意见
  feedback(params) {
    return post("userFeedback/feedback", params);
  },
  //联系我们
  selectContactUs(params) {
    return get("contactUs/selectContactUs", { params: params });
  },
  //友情链接
  selectLinkHead(params) {
    return get("linkHead/selectLinkHead", { params: params });
  },

  // 新街口 调用采编
  getHomeRotographList(params) {
    // 根据栏目id获取稿件列表
    return myRequest(`/site/${webName}/story/signatures/list`, params);
  },
  getManuscriptDetails(params) {
    // 根据稿件id获取稿件详情
    return get(`/site/${webName}/story/` + params + "/get");
  },
  // getColumn() { // 根据栏目id获取栏目
  //     return get(`/site/${webName}/column/listAll?mode=tree`)
  // }
  getColumn(mode = "tree", parentId = "") {
    // 根据栏目id获取栏目
    return get(
      `/site/${webName}/column/listAll?mode=${mode}&parentId=${parentId}`
    );
  },

  getTeachers(params) {
    // 师资
    // return myRequest(`/site/hqweb/story/signatures/list`, params);
    return myRequest(`/site/${webName}/story/signatures/list`, params);
  },
};
