import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    redirect: "",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/home1",
    name: "home1",
    redirect: "",
    component: () => import("../views/Home1.vue"),
  },
  {
    path: "/newsTrends",
    name: "newsTrends",
    component: () => import("../views/newsTrends.vue"),
    children: [
      {
        path: "/newsTrends/newsDetail/:columnId/:id",
        name: "newsDetailInner",
        component: () => import("../views/newsDetail.vue"),
      }
    ]
  },
  {
    path: "/newsDetail/:columnId/:id",
    name: "newsDetail",
    component: () => import("../views/newsDetail.vue"),
  },
  {
    path: "/phoneHome",
    name: "phoneHome",
    redirect: "",
    component: () => import("../views/PhoneHome.vue"),
  },
  {
    path: "/teachers",
    name: "teachers",
    redirect: "",
    component: () => import("../views/teacherTeam/index"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  //页面滚动到顶部
  window.scrollTo(0, 0);
  next();
});

export default router;
