export const allCollegeInfo = (vueInstance) => {
    return {
        whycbxy:{
            header: {
                Carousel:false,
                navList:[]
            },
            content: {
                homeArr: [],
                tabs: [],
            },
            footer: {
                hasQrCode:true
            }
        },
        gjjyxy: {
            header: {
                Carousel:true,
                navList:[
                    {
                        id: '1',
                        name: '首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '学院概况',
                        type: 'detail',
                        link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                    },
                    {
                        id: '3',
                        name: 'beat365亚洲官方网站',
                        type: 'detail',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['beat365亚洲官方网站'],
                                title: "beat365亚洲官方网站",
                                tab: '3',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '4',
                        name: '学院公告',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['学院公告'],
                                title: "学院公告",
                                tab: '4',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '5',
                        name: '招生信息',
                        children: [
                            {
                                name: "英国SQA项目",
                                id: vueInstance.$columnIdFile["英国SQA项目"],
                                type: "list",
                            },
                            {
                                name: "留学直通车",
                                id: vueInstance.$columnIdFile["留学直通车"],
                                type: "list",
                            },
                            {
                                name: "出国留学培训项目（2+2）",
                                id: vueInstance.$columnIdFile["出国留学培训项目（2+2）"],
                                type: "list",
                            },
                            {
                                name: "小语种/雅思培训",
                                id: vueInstance.$columnIdFile["小语种/雅思培训"],
                                type: "list",
                            },
                            {
                                name: "留学签证服务",
                                id: vueInstance.$columnIdFile["留学签证服务"],
                                type: "list",
                            },
                            {
                                name: "其他",
                                id: vueInstance.$columnIdFile["其他"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '6',
                        name: '缤纷校园',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['缤纷校园'],
                                title: "缤纷校园",
                                tab: '4',
                                hide: 'hide'
                            },
                        },
                    },
                    ]
            },
            content: {
                homeArr: [
                    {
                        title: 'beat365亚洲官方网站',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["beat365亚洲官方网站"]
                    },
                    {
                        title: '学院公告',
                        is: 'WgNotice',
                        id: vueInstance.$columnIdFile["学院公告"]
                    },
                    {
                        title: ['招生信息','缤纷校园'],
                        is: 'WgMediaDoubleRow',
                        maxLength: 3,
                        id: [vueInstance.$columnIdFile["招生信息"],vueInstance.$columnIdFile["缤纷校园"]]
                    },
                ],
                tabs: {
                    tabs1: [
                        { title: "beat365亚洲官方网站", columnId: vueInstance.$columnIdFile["beat365亚洲官方网站"], parentTitle: '新闻资讯' },
                        { title: "学院公告", columnId: vueInstance.$columnIdFile["学院公告"], parentTitle: '新闻资讯' },
                        { title: "招生信息", columnId: vueInstance.$columnIdFile["招生信息"], parentTitle: '新闻资讯' },
                        { title: "缤纷校园", columnId: vueInstance.$columnIdFile["缤纷校园"], parentTitle: '新闻资讯' }
                    ],
                    tabs3: [
                        { title: "beat365亚洲官方网站", columnId: vueInstance.$columnIdFile["beat365亚洲官方网站"], parentTitle: 'beat365亚洲官方网站' },
                    ],
                    tabs4: [
                        { title: "学院公告", columnId: vueInstance.$columnIdFile["学院公告"], parentTitle: '学院公告' },
                    ],
                    tabs5: [
                        { title: "英国SQA项目", columnId: vueInstance.$columnIdFile["学院公告"], parentTitle: '招生信息' },
                        { title: "留学直通车", columnId: vueInstance.$columnIdFile["留学直通车"], parentTitle: '招生信息' },
                        { title: "出国留学培训项目（2+2）", columnId: vueInstance.$columnIdFile["出国留学培训项目（2+2）"], parentTitle: '招生信息' },
                        { title: "小语种/雅思培训", columnId: vueInstance.$columnIdFile["小语种/雅思培训"], parentTitle: '招生信息' },
                        { title: "留学签证服务", columnId: vueInstance.$columnIdFile["留学签证服务"], parentTitle: '招生信息' },
                        { title: "其他", columnId: vueInstance.$columnIdFile["其他"], parentTitle: '招生信息' }
                    ],
                }

            },
            footer: {
                hasLink: false,
                hasQrCode: true
            }
        },
        mkszyxy: {
            header: {
                Carousel:true,
                navList:[
                    {
                        id: '1',
                        name: '首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '学院导航',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "学院简介",
                                id: vueInstance.$columnIdFile["学院简介"],
                                type: "detail",
                            },
                            {
                                name: "机构设置",
                                id: vueInstance.$columnIdFile["机构设置"],
                                type: "detail",
                            },
                            {
                                name: "学院领导",
                                id: vueInstance.$columnIdFile["学院领导"],
                                type: "detail",
                            },
                            {
                                name: "院长致辞",
                                id: vueInstance.$columnIdFile["院长致辞"],
                                type: "detail",
                            },
                            {
                                name: "联系我们",
                                id: vueInstance.$columnIdFile["联系我们"],
                                type: "detail",
                            },
                        ],
                    },
                    {
                        id: '3',
                        name: 'beat365亚洲官方网站',
                        type: 'detail',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['beat365亚洲官方网站'],
                                title: "beat365亚洲官方网站",
                                tab: '3',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '4',
                        name: '学院公告',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['学院公告'],
                                title: "学院公告",
                                tab: '4',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '5',
                        name: '师资队伍',
                        // link: '/teachers',
                        children: [
                            { name: "思政专职教师", id: vueInstance.$columnIdFile["思政专职教师"], type: "teachers" },
                            { name: "思政兼职教师", id: vueInstance.$columnIdFile["思政兼职教师"], type: "teachers" },
                            { name: "外聘专家", id: vueInstance.$columnIdFile["外聘专家"], type: "teachers" },
                        ]
                    },
                    {
                        id: '6',
                        name: '教育教学',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['教育教学'],
                                title: "教育教学",
                                tab: '6',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '7',
                        name: '学术科研',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['学术科研'],
                                title: "学术科研",
                                tab: '7',
                                hide: 'hide'
                            },
                        },
                    },  {
                        id: '8',
                        name: '党群工作',
                        // type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "党建工作",
                                id: vueInstance.$columnIdFile["党建工作"],
                                type: "list",
                            },
                            {
                                name: "工会工作",
                                id: vueInstance.$columnIdFile["工会工作"],
                                type: "list",
                            },
                            {
                                name: "学生园地",
                                id: vueInstance.$columnIdFile["学生园地"],
                                type: "list",
                            },
                        ]
                    }]
            },
            content: {
                homeArr: [
                    {
                        title: 'beat365亚洲官方网站',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["beat365亚洲官方网站"]
                    },
                    {
                        title: '学院公告',
                        is: 'WgNotice',
                        id: vueInstance.$columnIdFile["学院公告"]
                    },
                    {
                        title: '党群工作',
                        is: 'WgList',
                        maxLength: 6,
                        id: vueInstance.$columnIdFile["党群工作"]
                    },
                    {
                        title: '学术科研',
                        is: 'WgMedia',
                        maxLength: 6,
                        id: vueInstance.$columnIdFile["学术科研"]
                    },
                    {
                        title: '教育教学',
                        is: 'WgList',
                        maxLength: 6,
                        id: vueInstance.$columnIdFile["教育教学"]
                    },
                ],
                tabs: {
                    tabs1: [
                        { title: "beat365亚洲官方网站", columnId: vueInstance.$columnIdFile["beat365亚洲官方网站"], parentTitle: '新闻资讯' },
                        { title: "学院公告", columnId: vueInstance.$columnIdFile["学院公告"], parentTitle: '新闻资讯' },
                        { title: "党群工作", columnId: vueInstance.$columnIdFile["党群工作"], parentTitle: '新闻资讯' },
                        { title: "学术科研", columnId: vueInstance.$columnIdFile["学术科研"], parentTitle: '新闻资讯' },
                        { title: "教育教学", columnId: vueInstance.$columnIdFile["教育教学"], parentTitle: '新闻资讯' },
                    ],
                    tabs2: [
                        { title: "学院简介", columnId: vueInstance.$columnIdFile["学院简介"], parentTitle: '学院导航' },
                        { title: "机构设置", columnId: vueInstance.$columnIdFile["机构设置"], parentTitle: '学院导航' },
                        { title: "学院领导", columnId: vueInstance.$columnIdFile["学院领导"], parentTitle: '学院导航' },
                        { title: "院长致辞", columnId: vueInstance.$columnIdFile["院长致辞"], parentTitle: '学院导航' },
                        { title: "联系我们", columnId: vueInstance.$columnIdFile["联系我们"], parentTitle: '学院导航' },
                    ],
                    tabs3: [
                        { title: "beat365亚洲官方网站", columnId: vueInstance.$columnIdFile["beat365亚洲官方网站"], parentTitle: 'beat365亚洲官方网站' },
                    ],
                    tabs4: [
                        { title: "学院公告", columnId: vueInstance.$columnIdFile["学院公告"], parentTitle: '学院公告' },
                    ],
                    tabs5: [
                        { title: "思政专职教师", columnId: vueInstance.$columnIdFile["思政专职教师"], parentTitle: '师资队伍' },
                        { title: "思政兼职教师", columnId: vueInstance.$columnIdFile["思政兼职教师"], parentTitle: '师资队伍' },
                        { title: "外聘专家", columnId: vueInstance.$columnIdFile["外聘专家"], parentTitle: '师资队伍' },
                    ],
                    tabs6: [
                        { title: "教育教学", columnId: vueInstance.$columnIdFile["教育教学"], parentTitle: '教育教学' },
                    ],
                    tabs7: [
                        { title: "学术科研", columnId: vueInstance.$columnIdFile["学术科研"], parentTitle: '学术科研' },
                    ],
                    tabs8: [
                        { title: "党建工作", columnId: vueInstance.$columnIdFile["党建工作"], parentTitle: '党群工作' },
                        { title: "工会工作", columnId: vueInstance.$columnIdFile["工会工作"], parentTitle: '党群工作' },
                        { title: "学生园地", columnId: vueInstance.$columnIdFile["学生园地"], parentTitle: '党群工作' },
                    ],
                }

            },
            footer: {
                hasLink: true,
                hasQrCode: false,
                bottomArr: [
                    {
                        name: '中国社会科学网',
                        link: 'https://www.cssn.cn/'
                    },{
                        name: '求是理论网',
                        link: 'http://www.qstheory.cn/'
                    },{
                        name: '理论网',
                        link: 'https://www.cntheory.com/'
                    },
                ]
            }
        },
        zdwjxy: {
            header: {
                Carousel:false,
                navList:[
                    {
                        id: '1',
                        name: '首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '学院导航',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "学院简介",
                                id: vueInstance.$columnIdFile["学院简介"],
                                type: "detail",
                            },
                            {
                                name: "组织机构",
                                id: vueInstance.$columnIdFile["组织机构"],
                                type: "detail",
                            },
                            {
                                name: "联系我们",
                                id: vueInstance.$columnIdFile["联系我们"],
                                type: "detail",
                            },
                        ],
                    },
                    {
                        id: '3',
                        name: '师资队伍',
                        // link: '/teachers',
                        children: [
                            { name: "在职教师", id: vueInstance.$columnIdFile["在职教师"], type: "teachers" },
                            { name: "荣誉教授", id: vueInstance.$columnIdFile["荣誉教授"], type: "teachers" },
                            { name: "招聘信息", id: vueInstance.$columnIdFile["招聘信息"], type: "teachers" },
                        ]
                    },
                    {
                        id: '4',
                        name: '人才培养',
                        children: [
                            {
                                name: "本科生培养",
                                id: vueInstance.$columnIdFile["本科生培养"],
                                type: "list",
                            },
                            {
                                name: "研究生培养",
                                id: vueInstance.$columnIdFile["研究生培养"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '5',
                        name: '学术科研',
                        children: [
                            {
                                name: "国际交流",
                                id: vueInstance.$columnIdFile["国际交流"],
                                type: "list",
                            },
                            {
                                name: "交流项目",
                                id: vueInstance.$columnIdFile["交流项目"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '6',
                        name: '党群工作',
                        children: [
                            {
                                name: "党建动态",
                                id: vueInstance.$columnIdFile["党建动态"],
                                type: "list",
                            },
                            {
                                name: "工会动态",
                                id: vueInstance.$columnIdFile["工会动态"],
                                type: "list",
                            },
                            {
                                name: "团学动态",
                                id: vueInstance.$columnIdFile["团学动态"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '7',
                        name: '招生信息',
                        children: [
                            {
                                name: "本科生招生",
                                id: vueInstance.$columnIdFile["本科生招生"],
                                type: "list",
                            },
                            {
                                name: "研究生招生",
                                id: vueInstance.$columnIdFile["研究生招生"],
                                type: "list",
                            },
                            {
                                name: "留学生招生",
                                id: vueInstance.$columnIdFile["留学生招生"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '8',
                        name: '校友天地',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['校友天地'],
                                title: "校友天地",
                                tab: '8',
                                hide: 'hide'
                            },
                        },
                    },
                ]
            },
            content: {
                homeArr: [
                    {
                        title: '新闻资讯',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["新闻资讯"]
                    },
                    {
                        title: '通知公告',
                        is: 'WgNotice',
                        id: vueInstance.$columnIdFile["通知公告"]
                    },
                    {
                        title: ['学术信息','学者视角'],
                        is: 'WgMediaDoubleRow',
                        maxLength: 3,
                        id: [vueInstance.$columnIdFile["学术信息"],vueInstance.$columnIdFile["学者视角"]]
                    }
                ],
                tabs: {
                    tabs1: [
                        { title: "新闻资讯", columnId: vueInstance.$columnIdFile["新闻资讯"], parentTitle: '新闻资讯' },
                        { title: "通知公告", columnId: vueInstance.$columnIdFile["通知公告"], parentTitle: '新闻资讯' },
                        { title: "学术信息", columnId: vueInstance.$columnIdFile["学术信息"], parentTitle: '新闻资讯' },
                        { title: "学者视角", columnId: vueInstance.$columnIdFile["学者视角"], parentTitle: '新闻资讯' },
                    ],
                    tabs2: [
                        { title: "学院简介", columnId: vueInstance.$columnIdFile["学院简介"], parentTitle: '学院概况' },
                        { title: "组织机构", columnId: vueInstance.$columnIdFile["组织机构"], parentTitle: '学院概况' },
                        { title: "联系我们", columnId: vueInstance.$columnIdFile["联系我们"], parentTitle: '学院概况' },
                    ],
                    tabs3: [
                        { title: "在职教师", columnId: vueInstance.$columnIdFile["在职教师"], parentTitle: '师资队伍' },
                        { title: "荣誉教授", columnId: vueInstance.$columnIdFile["荣誉教授"], parentTitle: '师资队伍' },
                        { title: "招聘信息", columnId: vueInstance.$columnIdFile["招聘信息"], parentTitle: '师资队伍' },
                    ],
                    tabs4: [
                        { title: "本科生培养", columnId: vueInstance.$columnIdFile["本科生培养"], parentTitle: '人才培养' },
                        { title: "研究生培养", columnId: vueInstance.$columnIdFile["研究生培养"], parentTitle: '人才培养' },
                    ],
                    tabs5: [
                        { title: "国际交流", columnId: vueInstance.$columnIdFile["国际交流"], parentTitle: '学术科研' },
                        { title: "交流项目", columnId: vueInstance.$columnIdFile["交流项目"], parentTitle: '学术科研' },
                    ],
                    tabs6: [
                        { title: "党建动态", columnId: vueInstance.$columnIdFile["党建动态"], parentTitle: '党建工作' },
                        { title: "工会动态", columnId: vueInstance.$columnIdFile["工会动态"], parentTitle: '党建工作' },
                        { title: "国学动态", columnId: vueInstance.$columnIdFile["国学动态"], parentTitle: '党建工作' },
                    ],
                    tabs7: [
                        { title: "本科生招生", columnId: vueInstance.$columnIdFile["本科生招生"], parentTitle: '招生信息' },
                        { title: "研究生招生", columnId: vueInstance.$columnIdFile["研究生招生"], parentTitle: '招生信息' },
                        { title: "留学生招生", columnId: vueInstance.$columnIdFile["留学生招生"], parentTitle: '招生信息' },
                    ],
                    tabs8: [
                        { title: "校友天地", columnId: vueInstance.$columnIdFile["校友天地"], parentTitle: '校友天地' },
                    ],
                }

            },
            footer: {
                hasLink: false,
                hasQrCode: false,
                bottomArr: [
                    {
                        name: '中国社会科学网',
                        link: 'https://www.cssn.cn/'
                    },{
                        name: '求是理论网',
                        link: 'http://www.qstheory.cn/'
                    },{
                        name: '理论网',
                        link: 'https://www.cntheory.com/'
                    },
                ]
            }
        },
        jjxy: {
            header: {
                Carousel:true,
                navList:[
                    // {
                    //     id: '1',
                    //     name: '首页',
                    //     title: 'HOME',
                    //     link: '/home',
                    //     children: [],
                    // },
                    {
                        id: '2',
                        name: '学院导航',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "学院简介",
                                id: vueInstance.$columnIdFile["学院简介"],
                                type: "detail",
                            },
                            {
                                name: "学院领导",
                                id: vueInstance.$columnIdFile["学院领导"],
                                type: "detail",
                            },
                            {
                                name: "组织机构",
                                id: vueInstance.$columnIdFile["组织机构"],
                                type: "list",
                            },
                            {
                                name: "学院行政",
                                id: vueInstance.$columnIdFile["学院行政"],
                                type: "detail",
                            },
                            {
                                name: "联系我们",
                                id: vueInstance.$columnIdFile["联系我们"],
                                type: "detail",
                            },
                        ],
                    },
                    {
                        id: '3',
                        name: '新闻资讯',
                        children: [
                            {
                                name: "beat365亚洲官方网站",
                                id: vueInstance.$columnIdFile["beat365亚洲官方网站"],
                                type: "list",
                            },
                            {
                                name: "教学科研",
                                id: vueInstance.$columnIdFile["教学科研"],
                                type: "list",
                            },
                            {
                                name: "通知公告",
                                id: vueInstance.$columnIdFile["通知公告"],
                                type: "list",
                            },
                            {
                                name: "表格下载",
                                id: vueInstance.$columnIdFile["表格下载"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '4',
                        name: '师资队伍',
                        // link: '/teachers',
                        children: [
                            { name: "国际贸易系", id: vueInstance.$columnIdFile["国际贸易系"], type: "teachers" },
                            { name: "金融系", id: vueInstance.$columnIdFile["金融系"], type: "teachers" },
                            { name: "国际文化贸易系", id: vueInstance.$columnIdFile["国际文化贸易系"], type: "teachers" },
                            { name: "经济系", id: vueInstance.$columnIdFile["经济系"], type: "teachers" },
                        ]
                    },
                    {
                        id: '5',
                        name: '人才培养',
                        children: [
                            {
                                name: "本科生教育",
                                id: vueInstance.$columnIdFile["本科生教育"],
                                type: "list",
                            },
                            {
                                name: "研究生教育",
                                id: vueInstance.$columnIdFile["研究生教育"],
                                type: "list",
                            },
                            {
                                name: "实习实践",
                                id: vueInstance.$columnIdFile["实习实践"],
                                type: "list",
                            },
                            {
                                name: "基地建设",
                                id: vueInstance.$columnIdFile["基地建设"],
                                type: "list",
                            },
                            {
                                name: "MIB专题网站",
                                id: vueInstance.$columnIdFile["MIB专题网站"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '6',
                        name: '学术科研',
                        children: [
                            {
                                name: "科研项目",
                                id: vueInstance.$columnIdFile["科研项目"],
                                type: "list",
                            },
                            {
                                name: "科研成果",
                                id: vueInstance.$columnIdFile["科研成果"],
                                type: "list",
                            },
                            {
                                name: "科研奖励",
                                id: vueInstance.$columnIdFile["科研奖励"],
                                type: "list",
                            },
                            {
                                name: "团队建设",
                                id: vueInstance.$columnIdFile["团队建设"],
                                type: "list",
                            },
                            {
                                name: "研究中心",
                                id: vueInstance.$columnIdFile["研究中心"],
                                type: "list",
                            },
                        ]
                    },
                    // {
                    //     id: '7',
                    //     name: '研究中心',
                    //     link: {
                    //         path: "/newsTrends",
                    //         query: {
                    //             columnId: vueInstance.$columnIdFile['研究中心'],
                    //             title: "研究中心",
                    //             tab: '7',
                    //             hide: 'hide'
                    //         },
                    //     },
                    // },
                    {
                        id: '8',
                        name: '国际交流',
                        children: [
                            {
                                name: "合作院校",
                                id: vueInstance.$columnIdFile["合作院校"],
                                type: "list",
                            },
                            {
                                name: "留学项目",
                                id: vueInstance.$columnIdFile["留学项目"],
                                type: "list",
                            },
                            {
                                name: "外培风采",
                                id: vueInstance.$columnIdFile["外培风采"],
                                type: "list",
                            },
                            {
                                name: "学术交流",
                                id: vueInstance.$columnIdFile["学术交流"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '9',
                        name: '学生发展',
                        children: [
                            {
                                name: "学生活动",
                                id: vueInstance.$columnIdFile["学生活动"],
                                type: "list",
                            },
                            {
                                name: "奖助公示",
                                id: vueInstance.$columnIdFile["奖助公示"],
                                type: "list",
                            },
                            {
                                name: "社会奖学金",
                                id: vueInstance.$columnIdFile["社会奖学金"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '10',
                        name: '党建工作',
                        children: [
                            {
                                name: "支部建设",
                                id: vueInstance.$columnIdFile["支部建设"],
                                type: "list",
                            },
                            {
                                name: "职工之家",
                                id: vueInstance.$columnIdFile["职工之家"],
                                type: "list",
                            }
                        ]
                    },

                    {
                        id: '11',
                        name: '招生就业',
                        children: [
                            {
                                name: "招生信息",
                                id: vueInstance.$columnIdFile["招生信息"],
                                type: "list",
                            },
                            {
                                name: "就业信息",
                                id: vueInstance.$columnIdFile["就业信息"],
                                type: "list",
                            }
                        ]
                    },
                    {
                        id: '12',
                        name: '校友工作',
                        children: [
                            {
                                name: "校史院史",
                                id: vueInstance.$columnIdFile["校史院史"],
                                type: "list",
                            },
                            {
                                name: "校友风采",
                                id: vueInstance.$columnIdFile["校友风采"],
                                type: "list",
                            }
                        ]
                    },
                    {
                        id: '13',
                        name: '友情链接',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['友情链接'],
                                title: "友情链接",
                                tab: '13',
                                hide: 'hide'
                            },
                        },
                    },
                ]
            },
            content: {
                homeArr: [
                    {
                        title: 'beat365亚洲官方网站',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["beat365亚洲官方网站"]
                    },
                    {
                        title: '通知公告',
                        is: 'WgNotice',
                        id: vueInstance.$columnIdFile["通知公告"]
                    },
                    {
                        title: '学工动态',
                        is: 'WgPerson',
                        id: vueInstance.$columnIdFile["学工动态"]
                    },
                    {
                        title: '教学科研',
                        is: 'WgScienceTwo',
                        id: vueInstance.$columnIdFile["教学科研"]
                    },
                    {
                        title: '学者视点',
                        is: 'WgList',
                        id: vueInstance.$columnIdFile["学者视点"]
                    },
                    {
                        title: '招生就业',
                        is: 'WgScienceTwo',
                        id: vueInstance.$columnIdFile["招生就业"]
                    },
                    {
                        title: '国际交流',
                        is: 'WgMedia',
                        id: vueInstance.$columnIdFile["国际交流"]
                    },



                ],
                tabs: {
                    tabs1: [
                        { title: "beat365亚洲官方网站", columnId: vueInstance.$columnIdFile["beat365亚洲官方网站"], parentTitle: '新闻资讯' },
                        { title: "通知公告", columnId: vueInstance.$columnIdFile["通知公告"], parentTitle: '新闻资讯' },
                        { title: "学工动态", columnId: vueInstance.$columnIdFile["学工动态"], parentTitle: '新闻资讯' },
                        { title: "教学科研", columnId: vueInstance.$columnIdFile["教学科研"], parentTitle: '新闻资讯' },
                        { title: "学者视点", columnId: vueInstance.$columnIdFile["学者视点"], parentTitle: '新闻资讯' },
                        { title: "招生就业", columnId: vueInstance.$columnIdFile["招生就业"], parentTitle: '新闻资讯' },
                        { title: "国际交流", columnId: vueInstance.$columnIdFile["国际交流"], parentTitle: '新闻资讯' },
                    ],
                    tabs2: [
                        { title: "学院简介", columnId: vueInstance.$columnIdFile["学院简介"], parentTitle: '学院概况' },
                        { title: "学院领导", columnId: vueInstance.$columnIdFile["学院领导"], parentTitle: '学院概况' },
                        { title: "组织机构", columnId: vueInstance.$columnIdFile["组织机构"], parentTitle: '学院概况' },
                        { title: "学院行政", columnId: vueInstance.$columnIdFile["学院行政"], parentTitle: '学院概况' },
                        { title: "联系我们", columnId: vueInstance.$columnIdFile["联系我们"], parentTitle: '学院概况' },
                    ],
                    tabs3: [
                        { title: "beat365亚洲官方网站", columnId: vueInstance.$columnIdFile["beat365亚洲官方网站"], parentTitle: '新闻资讯' },
                        { title: "教学科研", columnId: vueInstance.$columnIdFile["教学科研"], parentTitle: '新闻资讯' },
                        { title: "通知公告", columnId: vueInstance.$columnIdFile["通知公告"], parentTitle: '新闻资讯' },
                        { title: "表格下载", columnId: vueInstance.$columnIdFile["表格下载"], parentTitle: '新闻资讯' },
                    ],
                    tabs4: [
                        { title: "国际贸易系", columnId: vueInstance.$columnIdFile["国际贸易系"], parentTitle: '师资队伍' },
                        { title: "金融系", columnId: vueInstance.$columnIdFile["金融系"], parentTitle: '师资队伍' },
                        { title: "国际文化贸易系", columnId: vueInstance.$columnIdFile["国际文化贸易系"], parentTitle: '师资队伍' },
                        { title: "经济系", columnId: vueInstance.$columnIdFile["经济系"], parentTitle: '师资队伍' },
                    ],
                    tabs5: [
                        { title: "本科生教育", columnId: vueInstance.$columnIdFile["本科生教育"], parentTitle: '人才培养' },
                        { title: "研究生教育", columnId: vueInstance.$columnIdFile["研究生教育"], parentTitle: '人才培养' },
                        { title: "实习实践", columnId: vueInstance.$columnIdFile["实习实践"], parentTitle: '人才培养' },
                        { title: "基地建设", columnId: vueInstance.$columnIdFile["基地建设"], parentTitle: '人才培养' },
                        { title: "MIB专题网站", columnId: vueInstance.$columnIdFile["MIB专题网站"], parentTitle: '人才培养' },
                    ],
                    tabs6: [
                        { title: "科研项目", columnId: vueInstance.$columnIdFile["科研项目"], parentTitle: '学术科研' },
                        { title: "科研成果", columnId: vueInstance.$columnIdFile["科研成果"], parentTitle: '学术科研' },
                        { title: "科研奖励", columnId: vueInstance.$columnIdFile["科研奖励"], parentTitle: '学术科研' },
                        { title: "团队建设", columnId: vueInstance.$columnIdFile["团队建设"], parentTitle: '学术科研' },
                        { title: "研究中心", columnId: vueInstance.$columnIdFile["研究中心"], parentTitle: '研究中心' }
                    ],
                    tabs7: [
                        { title: "研究中心", columnId: vueInstance.$columnIdFile["研究中心"], parentTitle: '研究中心' }
                    ],
                    tabs8: [
                        { title: "合作院校", columnId: vueInstance.$columnIdFile["合作院校"], parentTitle: '国际交流' },
                        { title: "留学项目", columnId: vueInstance.$columnIdFile["留学项目"], parentTitle: '国际交流' },
                        { title: "外培风采", columnId: vueInstance.$columnIdFile["外培风采"], parentTitle: '国际交流' },
                        { title: "学术交流", columnId: vueInstance.$columnIdFile["学术交流"], parentTitle: '国际交流' },
                    ],
                    tabs9: [
                        { title: "学生活动", columnId: vueInstance.$columnIdFile["学生活动"], parentTitle: '学生发展' },
                        { title: "奖助公示", columnId: vueInstance.$columnIdFile["奖助公示"], parentTitle: '学生发展' },
                        { title: "社会奖学金", columnId: vueInstance.$columnIdFile["社会奖学金"], parentTitle: '学生发展' },
                    ],
                    tabs10: [
                        { title: "支部建设", columnId: vueInstance.$columnIdFile["支部建设"], parentTitle: '党建工作' },
                        { title: "职工之家", columnId: vueInstance.$columnIdFile["职工之家"], parentTitle: '党建工作' },
                    ],
                    tabs11: [
                        { title: "招生信息", columnId: vueInstance.$columnIdFile["招生信息"], parentTitle: '招生就业' },
                        { title: "就业信息", columnId: vueInstance.$columnIdFile["就业信息"], parentTitle: '招生就业' },
                    ],
                    tabs12: [
                        { title: "校史院史", columnId: vueInstance.$columnIdFile["校史院史"], parentTitle: '校友工作' },
                        { title: "校友风采", columnId: vueInstance.$columnIdFile["校友风采"], parentTitle: '校友工作' },
                    ],
                    tabs13: [
                        { title: "友情链接", columnId: vueInstance.$columnIdFile["友情链接"], parentTitle: '友情链接' },
                    ],
                }

            },
            footer: {
                hasLink: true,
                hasQrCode: true,
                bottomArr: [
                    {
                        name: '文旅部',
                        link: 'https://www.mct.gov.cn/'
                    },{
                        name: '商务部',
                        link: 'http://www.mofcom.gov.cn/'
                    },{
                        name: 'MIB专题网站',
                        link: 'http://mib.bisu.edu.cn/'
                    },
                    {
                        name: '雨课堂',
                        link: 'https://bisu.yuketang.cn/'
                    },{
                        name: '论文系统',
                        link: 'bisu.co.cnki.net'
                    },{
                        name: '全国哲学社会科学规划',
                        link: 'http://www.nopss.gov.cn/'
                    },
                ],
                // qrCodeArr: ['douyin', 'weixin']
                qrCodeArr: []
            }
        },
        yzxy: {
            header: {
                Carousel:false,
                navList:[
                    {
                        id: '1',
                        name: '首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '学院导航',
                        type: 'detail',
                        children: [
                            {
                                name: "学院简介",
                                id: vueInstance.$columnIdFile["学院简介"],
                                type: "detail",
                            },
                            {
                                name: "学院领导",
                                id: vueInstance.$columnIdFile["学院领导"],
                                type: "detail",
                            },
                            {
                                name: "机构设置",
                                id: vueInstance.$columnIdFile["机构设置"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '3',
                        name: '师资队伍',
                        // link: '/teachers',
                        children: [
                            { name: "教授", id: vueInstance.$columnIdFile["教授"], type: "teachers" },
                            { name: "副教授", id: vueInstance.$columnIdFile["副教授"], type: "teachers" },
                            { name: "讲师", id: vueInstance.$columnIdFile["讲师"], type: "teachers" },
                            { name: "助教", id: vueInstance.$columnIdFile["助教"], type: "teachers" },
                            { name: "外教", id: vueInstance.$columnIdFile["外教"], type: "teachers" },
                        ]
                    },
                    {
                        id: '4',
                        name: '教学科研',
                        children: [
                            {
                                name: "专业设置",
                                id: vueInstance.$columnIdFile["专业设置"],
                                type: "list",
                            },
                            {
                                name: "课程描述",
                                id: vueInstance.$columnIdFile["课程描述"],
                                type: "list",
                            },
                            {
                                name: "人才培养理念",
                                id: vueInstance.$columnIdFile["人才培养理念"],
                                type: "list",
                            },
                            {
                                name: "教学动态",
                                id: vueInstance.$columnIdFile["教学动态"],
                                type: "list",
                            },
                            {
                                name: "科研项目",
                                id: vueInstance.$columnIdFile["科研项目"],
                                type: "list",
                            },
                            {
                                name: "科研成果",
                                id: vueInstance.$columnIdFile["科研成果"],
                                type: "list",
                            },
                            {
                                name: "科研奖励",
                                id: vueInstance.$columnIdFile["科研奖励"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '5',
                        name: '学生管理',
                        children: [
                            {
                                name: "管理团队",
                                id: vueInstance.$columnIdFile["管理团队"],
                                type: "detail",
                            },
                            {
                                name: "学生组织",
                                id: vueInstance.$columnIdFile["学生组织"],
                                type: "list",
                            },
                            {
                                name: "表格下载",
                                id: vueInstance.$columnIdFile["表格下载"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '6',
                        name: '招生就业',
                        children: [
                            {
                                name: "招生信息",
                                id: vueInstance.$columnIdFile["招生信息"],
                                type: "list",
                            },
                            {
                                name: "就业信息",
                                id: vueInstance.$columnIdFile["就业信息"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '7',
                        name: '国际交流',
                        children: [
                            {
                                name: "合作院校",
                                id: vueInstance.$columnIdFile["合作院校"],
                                type: "list",
                            },
                            {
                                name: "交流项目",
                                id: vueInstance.$columnIdFile["交流项目"],
                                type: "list",
                            },
                            {
                                name: "留学活动",
                                id: vueInstance.$columnIdFile["留学活动"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '8',
                        name: '党建思政',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['党建思政'],
                                title: "党建思政",
                                tab: '8',
                                hide: 'hide'
                            },
                        },
                    },
                ]
            },
            content: {
                homeArr: [
                    {
                        title: 'beat365亚洲官方网站',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["beat365亚洲官方网站"]
                    },
                    {
                        title: '学院公告',
                        is: 'WgNotice',
                        id: vueInstance.$columnIdFile["学院公告"]
                    },
                    {
                        title: ["党建思政","教学科研"],
                        is: 'WgMediaDoubleRow',
                        maxLength: 3,
                        id: [vueInstance.$columnIdFile["党建思政"],vueInstance.$columnIdFile["教学科研"]]
                    }
                    
                ],
                tabs: {
                    tabs1: [
                        { title: "beat365亚洲官方网站", columnId: vueInstance.$columnIdFile["beat365亚洲官方网站"], parentTitle: '新闻资讯' },
                        { title: "学院公告", columnId: vueInstance.$columnIdFile["学院公告"], parentTitle: '新闻资讯' },
                        { title: "党建思政", columnId: vueInstance.$columnIdFile["党建思政"], parentTitle: '新闻资讯' },
                        { title: "教学科研", columnId: vueInstance.$columnIdFile["教学科研"], parentTitle: '新闻资讯' },
                    ],
                    tabs2: [
                        { title: "学院简介", columnId: vueInstance.$columnIdFile["学院简介"], parentTitle: '学院导航' },
                        { title: "学院领导", columnId: vueInstance.$columnIdFile["学院领导"], parentTitle: '学院导航' },
                        { title: "机构设置", columnId: vueInstance.$columnIdFile["机构设置"], parentTitle: '学院导航' },
                    ],
                    tabs3: [
                        { title: "教授", columnId: vueInstance.$columnIdFile["教授"], parentTitle: '师资队伍' },
                        { title: "副教授", columnId: vueInstance.$columnIdFile["副教授"], parentTitle: '师资队伍' },
                        { title: "讲师", columnId: vueInstance.$columnIdFile["讲师"], parentTitle: '师资队伍' },
                        { title: "助教", columnId: vueInstance.$columnIdFile["助教"], parentTitle: '师资队伍' },
                        { title: "外教", columnId: vueInstance.$columnIdFile["外教"], parentTitle: '师资队伍' },
                    ],
                    tabs4: [
                        { title: "专业设置", columnId: vueInstance.$columnIdFile["专业设置"], parentTitle: '教学科研' },
                        { title: "课程描述", columnId: vueInstance.$columnIdFile["课程描述"], parentTitle: '教学科研' },
                        { title: "人才培养理念", columnId: vueInstance.$columnIdFile["人才培养理念"], parentTitle: '教学科研' },
                        { title: "教学动态", columnId: vueInstance.$columnIdFile["教学动态"], parentTitle: '教学科研' },
                        { title: "科研项目", columnId: vueInstance.$columnIdFile["科研项目"], parentTitle: '教学科研' },
                        { title: "科研成果", columnId: vueInstance.$columnIdFile["科研成果"], parentTitle: '教学科研' },
                        { title: "科研奖励", columnId: vueInstance.$columnIdFile["科研奖励"], parentTitle: '教学科研' },
                    ],
                    tabs5: [
                        { title: "管理团队", columnId: vueInstance.$columnIdFile["管理团队"], parentTitle: '学生管理' },
                        { title: "学生组织", columnId: vueInstance.$columnIdFile["学生组织"], parentTitle: '学生管理' },
                        { title: "表格下载", columnId: vueInstance.$columnIdFile["表格下载"], parentTitle: '学生管理' },
                    ],
                    tabs6: [
                        { title: "招生信息", columnId: vueInstance.$columnIdFile["招生信息"], parentTitle: '招生就业' },
                        { title: "就业信息", columnId: vueInstance.$columnIdFile["就业信息"], parentTitle: '招生就业' },
                    ],
                    tabs7: [
                        { title: "合作院校", columnId: vueInstance.$columnIdFile["合作院校"], parentTitle: '国际交流' },
                        { title: "交流项目", columnId: vueInstance.$columnIdFile["交流项目"], parentTitle: '国际交流' },
                        { title: "留学活动", columnId: vueInstance.$columnIdFile["留学活动"], parentTitle: '国际交流' },
                    ],
                    tabs8: [
                        { title: "党建思政", columnId: vueInstance.$columnIdFile["党建思政"], parentTitle: '党建思政' },
                    ],
                }

            },
            footer: {
                hasLink: false,
                hasQrCode: false,
                bottomArr: []
            }
        },
        zdxy: {
            header: {
                Carousel:true,
                navList:[
                    {
                        id: '1',
                        name: '首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '学院概况',
                        type: 'detail',
                        children: [
                            {
                                name: "学院简介",
                                id: vueInstance.$columnIdFile["学院简介"],
                                type: "detail",
                            },
                            {
                                name: "学院领导",
                                id: vueInstance.$columnIdFile["学院领导"],
                                type: "list",
                            },
                            {
                                name: "组织机构",
                                id: vueInstance.$columnIdFile["组织机构"],
                                type: "list",
                            },
                            {
                                name: "通知公告",
                                id: vueInstance.$columnIdFile["通知公告"],
                                type: "list",
                            },
                            {
                                name: "联系我们",
                                id: vueInstance.$columnIdFile["联系我们"],
                                type: "detail",
                            },
                        ],
                    },
                    {
                        id: '3',
                        name: '新闻信息',
                        children: [
                            {
                                name: "beat365亚洲官方网站",
                                id: vueInstance.$columnIdFile["beat365亚洲官方网站"],
                                type: "list",
                            },
                            {
                                name: "讲座信息",
                                id: vueInstance.$columnIdFile["讲座信息"],
                                type: "list",
                            },
                            {
                                name: "金驼故事",
                                id: vueInstance.$columnIdFile["金驼故事"],
                                type: "list",
                            },
                            {
                                name: "媒体聚焦",
                                id: vueInstance.$columnIdFile["媒体聚焦"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '4',
                        name: '师资队伍',
                        // link: '/teachers',
                        children: [
                            { name: "阿拉伯语", id: vueInstance.$columnIdFile["阿拉伯语"], type: "teachers" },
                            { name: "波斯语", id: vueInstance.$columnIdFile["波斯语"], type: "teachers" },
                            { name: "土耳其语", id: vueInstance.$columnIdFile["土耳其语"], type: "teachers" },
                            { name: "希伯来语", id: vueInstance.$columnIdFile["希伯来语"], type: "teachers" },
                        ]
                    },

                    {
                        id: '5',
                        name: '人才培养',
                        children: [
                            {
                                name: "本科生",
                                id: vueInstance.$columnIdFile["本科生"],
                                type: "list",
                            },
                            {
                                name: "研究生",
                                id: vueInstance.$columnIdFile["研究生"],
                                type: "list",
                            },
                            {
                                name: "招生信息",
                                id: vueInstance.$columnIdFile["招生信息"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '6',
                        name: '科学研究',
                        children: [
                            {
                                name: "科研项目",
                                id: vueInstance.$columnIdFile["科研项目"],
                                type: "list",
                            },
                            {
                                name: "科研成果",
                                id: vueInstance.$columnIdFile["科研成果"],
                                type: "list",
                            },
                            {
                                name: "科研获奖",
                                id: vueInstance.$columnIdFile["科研获奖"],
                                type: "list",
                            },
                            {
                                name: "科研活动",
                                id: vueInstance.$columnIdFile["科研活动"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '7',
                        name: '国际交流',
                        children: [],
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['国际交流'],
                                title: "国际交流",
                                tab: '7',
                                hide: 'hide'
                            },
                        },

                    },
                    {
                        id: '8',
                        name: '学生工作',
                        children: [
                            {
                                name: "第二课堂",
                                id: vueInstance.$columnIdFile["第二课堂"],
                                type: "list",
                            },
                            {
                                name: "实习就业",
                                id: vueInstance.$columnIdFile["实习就业"],
                                type: "list",
                            },
                            {
                                name: "表格下载",
                                id: vueInstance.$columnIdFile["表格下载"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '9',
                        name: '党群工作',
                        children: [
                            {
                                name: "党建风采",
                                id: vueInstance.$columnIdFile["党建风采"],
                                type: "list",
                            },
                            {
                                name: "学生党支部",
                                id: vueInstance.$columnIdFile["学生党支部"],
                                type: "list",
                            },
                            {
                                name: "教职工党支部",
                                id: vueInstance.$columnIdFile["教职工党支部"],
                                type: "list",
                            },
                            {
                                name: "研究生党支部",
                                id: vueInstance.$columnIdFile["研究生党支部"],
                                type: "list",
                            },
                            {
                                name: "教工之家",
                                id: vueInstance.$columnIdFile["教工之家"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '10',
                        name: '校友风采',
                        children: [],
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['校友风采'],
                                title: "校友风采",
                                tab: '10',
                                hide: 'hide'
                            },
                        },

                    },
                ]
            },
            content: {
                homeArr: [
                    {
                        title: 'beat365亚洲官方网站',
                        is: 'WgNewsTwo',
                        id: vueInstance.$columnIdFile["beat365亚洲官方网站"]
                    },
                    {
                        title: '党群工作',
                        is: 'WgList',
                        id: vueInstance.$columnIdFile["党群工作"]
                    },
                    {
                        title: '通知公告',
                        is: 'WgNoticeTwo',
                        id: vueInstance.$columnIdFile["通知公告"]
                    },
                    {
                        title: '媒体聚焦',
                        is: 'WgMedia',
                        id: vueInstance.$columnIdFile["媒体聚焦"]
                    },
                ],
                tabs: {
                    tabs1: [
                        { title: "beat365亚洲官方网站", columnId: vueInstance.$columnIdFile["beat365亚洲官方网站"], parentTitle: '新闻资讯' },
                        { title: "党群工作", columnId: vueInstance.$columnIdFile["党群工作"], parentTitle: '新闻资讯' },
                        { title: "通知公告", columnId: vueInstance.$columnIdFile["通知公告"], parentTitle: '新闻资讯' },
                        { title: "媒体聚焦", columnId: vueInstance.$columnIdFile["媒体聚焦"], parentTitle: '新闻资讯' },
                    ],
                    tabs2: [
                        { title: "学院介绍", columnId: vueInstance.$columnIdFile["学院介绍"], parentTitle: '学院概况' },
                        { title: "学院领导", columnId: vueInstance.$columnIdFile["学院领导"], parentTitle: '学院概况' },
                        { title: "组织机构", columnId: vueInstance.$columnIdFile["组织机构"], parentTitle: '学院概况' },
                        { title: "通知公告", columnId: vueInstance.$columnIdFile["通知公告"], parentTitle: '学院概况' },
                        { title: "联系我们", columnId: vueInstance.$columnIdFile["联系我们"], parentTitle: '学院概况' },
                    ],
                    tabs3: [
                        { title: "beat365亚洲官方网站", columnId: vueInstance.$columnIdFile["beat365亚洲官方网站"], parentTitle: '新闻信息' },
                        { title: "讲座信息", columnId: vueInstance.$columnIdFile["讲座信息"], parentTitle: '新闻信息' },
                        { title: "金驼故事", columnId: vueInstance.$columnIdFile["金驼故事"], parentTitle: '新闻信息' },
                        { title: "媒体聚焦", columnId: vueInstance.$columnIdFile["媒体聚焦"], parentTitle: '新闻信息' },
                    ],
                    tabs4: [
                        { title: "阿拉伯语", columnId: vueInstance.$columnIdFile["阿拉伯语"], parentTitle: '师资队伍' },
                        { title: "波斯语", columnId: vueInstance.$columnIdFile["波斯语"], parentTitle: '师资队伍' },
                        { title: "土耳其语", columnId: vueInstance.$columnIdFile["土耳其语"], parentTitle: '师资队伍' },
                        { title: "希伯来语", columnId: vueInstance.$columnIdFile["希伯来语"], parentTitle: '师资队伍' },
                    ],
                    tabs5: [
                        { title: "本科生", columnId: vueInstance.$columnIdFile["本科生"], parentTitle: '人才培养' },
                        { title: "研究生", columnId: vueInstance.$columnIdFile["研究生"], parentTitle: '人才培养' },
                        { title: "招生信息", columnId: vueInstance.$columnIdFile["招生信息"], parentTitle: '人才培养' },
                    ],
                    tabs6: [
                        { title: "科研项目", columnId: vueInstance.$columnIdFile["科研项目"], parentTitle: '科学研究' },
                        { title: "科研成果", columnId: vueInstance.$columnIdFile["科研成果"], parentTitle: '科学研究' },
                        { title: "科研获奖", columnId: vueInstance.$columnIdFile["科研获奖"], parentTitle: '科学研究' },
                        { title: "科研活动", columnId: vueInstance.$columnIdFile["科研活动"], parentTitle: '科学研究' },
                    ],
                    tabs7: [
                        { title: "国际交流", columnId: vueInstance.$columnIdFile["国际交流"], parentTitle: '国际交流' },
                    ],
                    tabs8: [
                        { title: "第二课堂", columnId: vueInstance.$columnIdFile["第二课堂"], parentTitle: '学生工作' },
                        { title: "实习就业", columnId: vueInstance.$columnIdFile["实习就业"], parentTitle: '学生工作' },
                        { title: "表格下载", columnId: vueInstance.$columnIdFile["表格下载"], parentTitle: '学生工作' },
                    ],
                    tabs9: [
                        { title: "党建风采", columnId: vueInstance.$columnIdFile["党建风采"], parentTitle: '党群工作' },
                        { title: "学生党支部", columnId: vueInstance.$columnIdFile["学生党支部"], parentTitle: '党群工作' },
                        { title: "教职工党支部", columnId: vueInstance.$columnIdFile["教职工党支部"], parentTitle: '党群工作' },
                        { title: "研究生党支部", columnId: vueInstance.$columnIdFile["研究生党支部"], parentTitle: '党群工作' },
                        { title: "教工之家", columnId: vueInstance.$columnIdFile["教工之家"], parentTitle: '党群工作' },
                    ],
                    tabs10: [
                        { title: "校友风采", columnId: vueInstance.$columnIdFile["校友风采"], parentTitle: '校友风采' },
                    ],
                }

            },
            footer: {
                hasLink: false,
                hasQrCode: false,
                bottomArr: []
            }
        },
        ryxy: {
            header: {
                Carousel:false,
                navList:[
                    {
                        id: '1',
                        name: '首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '院系介绍',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "学院简介",
                                id: vueInstance.$columnIdFile["学院简介"],
                                type: "detail",
                            },
                            {
                                name: "院长致辞",
                                id: vueInstance.$columnIdFile["院长致辞"],
                                type: "detail",
                            },
                            {
                                name: "机构设置",
                                id: vueInstance.$columnIdFile["机构设置"],
                                type: "detail",
                            },
                            {
                                name: "师资队伍",
                                id: vueInstance.$columnIdFile["师资队伍"],
                                type: "detail",
                            },
                        ],
                    },
                    {
                        id: '3',
                        name: 'beat365亚洲官方网站',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['beat365亚洲官方网站'],
                                title: "beat365亚洲官方网站",
                                tab: '3',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '4',
                        name: '教学科研',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['教学科研'],
                                title: "教学科研",
                                tab: '4',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '5',
                        name: '国际交流',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['国际交流'],
                                title: "国际交流",
                                tab: '5',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '6',
                        name: '学生园地',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['学生园地'],
                                title: "学生园地",
                                tab: '6',
                                hide: 'hide'
                            },
                        },
                    },

                    {
                        id: '7',
                        name: '党团工会',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['党团工会'],
                                title: "党团工会",
                                tab: '7',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '8',
                        name: '校友风采',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['校友风采'],
                                title: "校友风采",
                                tab: '8',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '9',
                        name: '口译大赛',
                        children: [
                            {
                                name: "大赛公告",
                                id: vueInstance.$columnIdFile["大赛公告"],
                                type: "list",
                            },
                            {
                                name: "往届风采",
                                id: vueInstance.$columnIdFile["往届风采"],
                                type: "list",
                            },
                            {
                                name: "比赛样题",
                                id: vueInstance.$columnIdFile["比赛样题"],
                                type: "list",
                            },
                            {
                                name: "表格下载",
                                id: vueInstance.$columnIdFile["表格下载"],
                                type: "list",
                            },
                            {
                                name: "联系方式",
                                id: vueInstance.$columnIdFile["联系方式"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '10',
                        name: '重要通知',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['重要通知'],
                                title: "重要通知",
                                tab: '10',
                                hide: 'hide'
                            },
                        },
                    },
                ]
            },
            content: {
                homeArr: [
                    {
                        title: 'beat365亚洲官方网站',
                        is: 'WgPerson',
                        id: vueInstance.$columnIdFile["beat365亚洲官方网站"]
                    },
                    {
                        title: '重要通知',
                        is: 'WgNoticeTwo',
                        id: vueInstance.$columnIdFile["重要通知"]
                    },
                    {
                        title: '教学科研',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["教学科研"]
                    },
                    {
                        title: '学生园地',
                        is: 'WgPerson',
                        id: vueInstance.$columnIdFile["学生园地"]
                    },
                    // {
                    //     title: '校友风采',
                    //     is: 'WgNews',
                    //     id: vueInstance.$columnIdFile["校友风采"]
                    // },
                    {
                        title: '党团工会',
                        is: 'WgTrade',
                        id: vueInstance.$columnIdFile["党团工会"]
                    },
                ],
                tabs: {
                    tabs1: [
                        { title: "beat365亚洲官方网站", columnId: vueInstance.$columnIdFile["beat365亚洲官方网站"], parentTitle: '新闻资讯' },
                        { title: "重要通知", columnId: vueInstance.$columnIdFile["重要通知"], parentTitle: '新闻资讯' },
                        { title: "教学科研", columnId: vueInstance.$columnIdFile["教学科研"], parentTitle: '新闻资讯' },
                        { title: "学生园地", columnId: vueInstance.$columnIdFile["学生园地"], parentTitle: '新闻资讯' },
                        { title: "校友风采", columnId: vueInstance.$columnIdFile["校友风采"], parentTitle: '新闻资讯' },
                        { title: "党团工会", columnId: vueInstance.$columnIdFile["党团工会"], parentTitle: '新闻资讯' },
                    ],
                    tabs2: [
                        { title: "学院简介", columnId: vueInstance.$columnIdFile["学院简介"], parentTitle: '院系介绍' },
                        { title: "院长致辞", columnId: vueInstance.$columnIdFile["院长致辞"], parentTitle: '院系介绍' },
                        { title: "机构设置", columnId: vueInstance.$columnIdFile["机构设置"], parentTitle: '院系介绍' },
                        { title: "师资队伍", columnId: vueInstance.$columnIdFile["师资队伍"], parentTitle: '院系介绍' },
                    ],
                    tabs3: [
                        { title: "beat365亚洲官方网站", columnId: vueInstance.$columnIdFile["beat365亚洲官方网站"], parentTitle: 'beat365亚洲官方网站' },
                    ],
                    tabs4: [
                        { title: "教学科研", columnId: vueInstance.$columnIdFile["教学科研"], parentTitle: '教学科研' },
                    ],
                    tabs5: [
                        { title: "国际交流", columnId: vueInstance.$columnIdFile["国际交流"], parentTitle: '国际交流' },
                    ],
                    tabs6: [
                        { title: "学生园地", columnId: vueInstance.$columnIdFile["学生园地"], parentTitle: '学生园地' },
                    ],
                    tabs7: [
                        { title: "党团工会", columnId: vueInstance.$columnIdFile["党团工会"], parentTitle: '党团工会' }
                    ],
                    tabs8: [
                        { title: "校友风采", columnId: vueInstance.$columnIdFile["校友风采"], parentTitle: '校友风采' },
                    ],
                    tabs9: [
                        { title: "大赛公告", columnId: vueInstance.$columnIdFile["大赛公告"], parentTitle: '口译大赛' },
                        { title: "往届风采", columnId: vueInstance.$columnIdFile["往届风采"], parentTitle: '口译大赛' },
                        { title: "比赛样题", columnId: vueInstance.$columnIdFile["比赛样题"], parentTitle: '口译大赛' },
                        { title: "表格下载", columnId: vueInstance.$columnIdFile["表格下载"], parentTitle: '口译大赛' },
                        { title: "联系方式", columnId: vueInstance.$columnIdFile["联系方式"], parentTitle: '口译大赛' },
                    ],
                    tabs10: [
                        { title: "重要通知", columnId: vueInstance.$columnIdFile["重要通知"], parentTitle: '重要通知' },
                    ],
                }

            },
            footer: {
                hasLink: true,
                hasQrCode: true,
                bottomArr: [
                    {
                        name: '本科生招生网',
                        link: 'https://bkzs.bisu.edu.cn/'
                    },{
                        name: '研招网',
                        link: 'https://yz.bisu.edu.cn/'
                    },{
                        name: '二外校友网',
                        link: 'http://bisuer.bisu.edu.cn/'
                    },
                    {
                        name: '二外就业网',
                        link: 'http://jiuye.bisu.edu.cn/'
                    }
                ],
                qrCodeArr: ['weixin', 'weixin1']
            }
        },
        hyxy: {
            header: {
                Carousel:false,
                navList:[
                    {
                        id: '1',
                        name: '首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '学院概况',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "学院简介",
                                id: vueInstance.$columnIdFile["学院简介"],
                                type: "detail",
                            },
                            {
                                name: "院长寄语",
                                id: vueInstance.$columnIdFile["院长寄语"],
                                type: "detail",
                            },
                            {
                                name: "学院领导",
                                id: vueInstance.$columnIdFile["学院领导"],
                                type: "list",
                            },
                            {
                                name: "机构设置",
                                id: vueInstance.$columnIdFile["机构设置"],
                                type: "detail",
                            },
                        ],
                    },
                    {
                        id: '3',
                        name: '师资队伍',
                        // link: '/teachers',
                        children: [
                            { name: "教授", id: vueInstance.$columnIdFile["教授"], type: "teachers" },
                            { name: "副教授", id: vueInstance.$columnIdFile["副教授"], type: "teachers" },
                            { name: "讲师", id: vueInstance.$columnIdFile["讲师"], type: "teachers" },
                            { name: "业届导师", id: vueInstance.$columnIdFile["业届导师"], type: "teachers" },
                            { name: "客座专家", id: vueInstance.$columnIdFile["客座专家"], type: "teachers" },
                            { name: "管理团队", id: vueInstance.$columnIdFile["管理团队"], type: "teachers" },
                        ]
                    },
                    {
                        id: '4',
                        name: '招生咨询',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['招生咨询'],
                                title: "招生咨询",
                                tab: '4',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '5',
                        name: '党建引领',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['党建引领'],
                                title: "党建引领",
                                tab: '5',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '6',
                        name: '教学科研',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['教学科研'],
                                title: "教学科研",
                                tab: '6',
                                hide: 'hide'
                            },
                        },
                    },

                    {
                        id: '7',
                        name: '学工动态',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['学工动态'],
                                title: "学工动态",
                                tab: '7',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '8',
                        name: '学生事务',
                        children: [
                            {
                                name: "制度规定",
                                id: vueInstance.$columnIdFile["制度规定"],
                                type: "list",
                            },
                            {
                                name: "生活指南",
                                id: vueInstance.$columnIdFile["生活指南"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '9',
                        name: 'HSK考试',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['HSK考试'],
                                title: "HSK考试",
                                tab: '9',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '10',
                        name: '视听说中心',
                        children: [
                            {
                                name: "中心简介",
                                id: vueInstance.$columnIdFile["中心简介"],
                                type: "detail",
                            },
                            {
                                name: "组织机构",
                                id: vueInstance.$columnIdFile["组织机构"],
                                type: "detail",
                            },
                            {
                                name: "学术研究",
                                id: vueInstance.$columnIdFile["学术研究"],
                                type: "list",
                            },
                            {
                                name: "中心动态",
                                id: vueInstance.$columnIdFile["中心动态"],
                                type: "list",
                            },
                        ]
                    },
                ]
            },
            content: {
                homeArr: [
                    {
                        title: '学院新闻 News&Event',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["学院新闻"]
                    },
                    {
                        title: '招生咨询 Admissions ',
                        is: 'WgList',
                        id: vueInstance.$columnIdFile["招生咨询"]
                    },
                    {
                        title: '党建引领 Party Construction',
                        is: 'WgTrade',
                        id: vueInstance.$columnIdFile["党建引领"]
                    },
                    {
                        title: '教学科研 Teaching&Research',
                        is: 'WgList',
                        id: vueInstance.$columnIdFile["教学科研"]
                    },
                    {
                        title: '学工动态 Student Affairs',
                        is: 'WgPerson',
                        id: vueInstance.$columnIdFile["学工动态"]
                    },
                    {
                        title: '中外学生风采 Student Demeanor',
                        is: 'WgScienceTwo',
                        id: vueInstance.$columnIdFile["中外学生风采"]
                    },
                ],
                tabs: {
                    tabs1: [
                        { title: "学院新闻", columnId: vueInstance.$columnIdFile["学院新闻"], parentTitle: '新闻资讯' },
                        { title: "招生咨询", columnId: vueInstance.$columnIdFile["招生咨询"], parentTitle: '新闻资讯' },
                        { title: "党建引领", columnId: vueInstance.$columnIdFile["党建引领"], parentTitle: '新闻资讯' },
                        { title: "教学科研", columnId: vueInstance.$columnIdFile["教学科研"], parentTitle: '新闻资讯' },
                        { title: "学工动态", columnId: vueInstance.$columnIdFile["学工动态"], parentTitle: '新闻资讯' },
                        { title: "中外学生风采", columnId: vueInstance.$columnIdFile["中外学生风采"], parentTitle: '新闻资讯' },
                    ],
                    tabs2: [
                        { title: "学院简介", columnId: vueInstance.$columnIdFile["学院简介"], parentTitle: '学院概况' },
                        { title: "院长寄语", columnId: vueInstance.$columnIdFile["院长寄语"], parentTitle: '学院概况' },
                        { title: "学院领导", columnId: vueInstance.$columnIdFile["学院领导"], parentTitle: '学院概况' },
                        { title: "机构设置", columnId: vueInstance.$columnIdFile["机构设置"], parentTitle: '学院概况' },
                    ],
                    tabs3: [
                        { title: "教授", columnId: vueInstance.$columnIdFile["教授"], parentTitle: '师资队伍' },
                        { title: "副教授", columnId: vueInstance.$columnIdFile["副教授"], parentTitle: '师资队伍' },
                        { title: "讲师", columnId: vueInstance.$columnIdFile["讲师"], parentTitle: '师资队伍' },
                        { title: "业届导师", columnId: vueInstance.$columnIdFile["业届导师"], parentTitle: '师资队伍' },
                        { title: "客座专家", columnId: vueInstance.$columnIdFile["客座专家"], parentTitle: '师资队伍' },
                        { title: "管理团队", columnId: vueInstance.$columnIdFile["管理团队"], parentTitle: '师资队伍' },
                    ],
                    tabs4: [
                        { title: "招生咨询", columnId: vueInstance.$columnIdFile["招生咨询"], parentTitle: '招生咨询' },
                    ],
                    tabs5: [
                        { title: "党建引领", columnId: vueInstance.$columnIdFile["党建引领"], parentTitle: '党建引领' },
                    ],
                    tabs6: [
                        { title: "教学科研", columnId: vueInstance.$columnIdFile["教学科研"], parentTitle: '教学科研' },
                    ],
                    tabs7: [
                        { title: "学工动态", columnId: vueInstance.$columnIdFile["学工动态"], parentTitle: '学工动态' }
                    ],
                    tabs8: [
                        { title: "制度规定", columnId: vueInstance.$columnIdFile["制度规定"], parentTitle: '学生事务' },
                        { title: "生活指南", columnId: vueInstance.$columnIdFile["生活指南"], parentTitle: '学生事务' },
                    ],
                    tabs9: [
                        { title: "HSK考试", columnId: vueInstance.$columnIdFile["HSK考试"], parentTitle: 'HSK考试' },
                    ],
                    tabs10: [
                        // { title: "中心简介", columnId: vueInstance.$columnIdFile["中心简介"], parentTitle: '视听说中心' },
                        // { title: "组织机构", columnId: vueInstance.$columnIdFile["组织机构"], parentTitle: '视听说中心' },
                        { title: "学术研究", columnId: vueInstance.$columnIdFile["学术研究"], parentTitle: '视听说中心' },
                        { title: "beat365亚洲官方网站", columnId: vueInstance.$columnIdFile["beat365亚洲官方网站"], parentTitle: '视听说中心' },
                    ],
                }

            },
            footer: {
                hasLink: true,
                hasQrCode: true,
                bottomArr: [
                    {
                        name: '二外就业网',
                        link: 'http://jiuye.bisu.edu.cn/'
                    },{
                        name: '校友网',
                        link: 'http://bisuer.bisu.edu.cn/'
                    },{
                        name: '二外招生网',
                        link: 'https://bkzs.bisu.edu.cn/'
                    },
                ],
                qrCodeArr: ['weixin']
            }
        },
        lykxxy: {
            header: {
                Carousel:true,
                navList:[
                    {
                        id: '1',
                        name: '首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '关于学院',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "学院简介",
                                id: vueInstance.$columnIdFile["学院简介"],
                                type: "detail",
                            },
                            {
                                name: "院长致辞",
                                id: vueInstance.$columnIdFile["院长致辞"],
                                type: "detail",
                            },
                            {
                                name: "领导团队",
                                id: vueInstance.$columnIdFile["领导团队"],
                                type: "list",
                            },
                            {
                                name: "组织机构",
                                id: vueInstance.$columnIdFile["组织机构"],
                                type: "list",
                            },
                            {
                                name: "联系我们",
                                id: vueInstance.$columnIdFile["联系我们"],
                                type: "detail",
                            },
                        ],
                    },
                    {
                        id: '3',
                        name: '新闻资讯',
                        children: [
                            {
                                name: "beat365亚洲官方网站",
                                id: vueInstance.$columnIdFile["beat365亚洲官方网站"],
                                type: "list",
                            },
                            {
                                name: "通知公告",
                                id: vueInstance.$columnIdFile["通知公告"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '4',
                        name: '师资队伍',
                        children: [
                            {
                                name: "旅游管理系",
                                id: vueInstance.$columnIdFile["旅游管理系"],
                                type: "teachers",
                            },
                            {
                                name: "旅游规划系",
                                id: vueInstance.$columnIdFile["旅游规划系"],
                                type: "teachers",
                            },
                            {
                                name: "旅游营销与电子商务系",
                                id: vueInstance.$columnIdFile["旅游营销与电子商务系"],
                                type: "teachers",
                            },
                            {
                                name: "酒店管理系",
                                id: vueInstance.$columnIdFile["酒店管理系"],
                                type: "teachers",
                            },
                            {
                                name: "健康产业管理系",
                                id: vueInstance.$columnIdFile["健康产业管理系"],
                                type: "teachers",
                            },
                            {
                                name: "会展经济与管理系",
                                id: vueInstance.$columnIdFile["会展经济与管理系"],
                                type: "teachers",
                            },
                            {
                                name: "客座教授",
                                id: vueInstance.$columnIdFile["旅游管理系"],
                                type: "teachers",
                            },
                            {
                                name: "院行政",
                                id: vueInstance.$columnIdFile["旅游规划系"],
                                type: "teachers",
                            },
                        ]
                    },
                    {
                        id: '5',
                        name: '导师队伍',
                        // link: '/teachers',
                        children: [
                            { name: "旅游管理专业导师(经济方向)", id: vueInstance.$columnIdFile["旅游管理专业导师(经济方向)"], type: "teachers" },
                            { name: "旅游管理专业导师(规划方向)", id: vueInstance.$columnIdFile["旅游管理专业导师(规划方向)"], type: "teachers" },
                            { name: "旅游管理专业导师(营销方向)", id: vueInstance.$columnIdFile["旅游管理专业导师(营销方向)"], type: "teachers" },
                            { name: "饭店管理专业导师", id: vueInstance.$columnIdFile["饭店管理专业导师"], type: "teachers" },
                            { name: "会展管理专业导师", id: vueInstance.$columnIdFile["会展管理专业导师"], type: "teachers" },
                        ]
                    },
                    {
                        id: '6',
                        name: '教学科研',
                        children: [
                            {
                                name: "本科生教育",
                                id: vueInstance.$columnIdFile["本科生教育"],
                                type: "list",
                            },
                            {
                                name: "研究生教育",
                                id: vueInstance.$columnIdFile["研究生教育"],
                                type: "list",
                            },
                            {
                                name: "科学研究",
                                id: vueInstance.$columnIdFile["科学研究"],
                                type: "list",
                            },
                            {
                                name: "社会服务",
                                id: vueInstance.$columnIdFile["社会服务"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '7',
                        name: '学工动态',
                        children: [
                            {
                                name: "学生活动",
                                id: vueInstance.$columnIdFile["学生活动"],
                                type: "list",
                            },
                            {
                                name: "学生获奖",
                                id: vueInstance.$columnIdFile["学生获奖"],
                                type: "list",
                            },
                            {
                                name: "企业奖学金",
                                id: vueInstance.$columnIdFile["企业奖学金"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '8',
                        name: '国际合作',
                        children: [
                            {
                                name: "交流项目",
                                id: vueInstance.$columnIdFile["交流项目"],
                                type: "list",
                            },
                            {
                                name: "留学生教育",
                                id: vueInstance.$columnIdFile["留学生教育"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '9',
                        name: '招生就业',
                        children: [
                            {
                                name: "本科生招生",
                                id: vueInstance.$columnIdFile["本科生招生"],
                                type: "list",
                            },
                            {
                                name: "研究生招生",
                                id: vueInstance.$columnIdFile["研究生招生"],
                                type: "list",
                            },
                            {
                                name: "留学生招生",
                                id: vueInstance.$columnIdFile["留学生招生"],
                                type: "list",
                            },
                            {
                                name: "就业指导",
                                id: vueInstance.$columnIdFile["就业指导"],
                                type: "list",
                            },
                            {
                                name: "校友风采",
                                id: vueInstance.$columnIdFile["校友风采"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '10',
                        name: '党建工会',
                        children: [
                            {
                                name: "党建工作",
                                id: vueInstance.$columnIdFile["党建工作"],
                                type: "list",
                            },
                            {
                                name: "工会工作",
                                id: vueInstance.$columnIdFile["工会工作"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '11',
                        name: '表格下载',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['表格下载'],
                                title: "表格下载",
                                tab: '11',
                                hide: 'hide'
                            },
                        },
                    },
                ]
            },
            content: {
                homeArr: [
                    {
                        title: 'beat365亚洲官方网站',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["beat365亚洲官方网站"]
                    },
                    {
                        title: '通知公告',
                        is: 'WaNotice',
                        id: vueInstance.$columnIdFile["通知公告"]
                    },
                    {
                        title: '教学科研',
                        is: 'WgScienceTwo',
                        id: vueInstance.$columnIdFile["教学科研"]
                    },
                    {
                        title: '学工动态',
                        is: 'WgPerson',
                        id: vueInstance.$columnIdFile["学工动态"]
                    },
                    {
                        title: '党建工会',
                        is: 'WgTrade',
                        id: vueInstance.$columnIdFile["党建工会"]
                    },
                ],
                tabs: {
                    tabs1: [
                        { title: "beat365亚洲官方网站", columnId: vueInstance.$columnIdFile["beat365亚洲官方网站"], parentTitle: '新闻资讯' },
                        { title: "通知公告", columnId: vueInstance.$columnIdFile["通知公告"], parentTitle: '新闻资讯' },
                        { title: "教学科研", columnId: vueInstance.$columnIdFile["教学科研"], parentTitle: '新闻资讯' },
                        { title: "学工动态", columnId: vueInstance.$columnIdFile["学工动态"], parentTitle: '新闻资讯' },
                        { title: "党建工会", columnId: vueInstance.$columnIdFile["党建工会"], parentTitle: '新闻资讯' },
                    ],
                    tabs2: [
                        { title: "学院简介", columnId: vueInstance.$columnIdFile["学院简介"], parentTitle: '关于学院' },
                        { title: "院长致辞", columnId: vueInstance.$columnIdFile["院长致辞"], parentTitle: '关于学院' },
                        { title: "领导团队", columnId: vueInstance.$columnIdFile["领导团队"], parentTitle: '关于学院' },
                        { title: "组织机构", columnId: vueInstance.$columnIdFile["组织机构"], parentTitle: '关于学院' },
                        { title: "联系我们", columnId: vueInstance.$columnIdFile["联系我们"], parentTitle: '关于学院' },
                    ],
                    tabs3: [
                        { title: "beat365亚洲官方网站", columnId: vueInstance.$columnIdFile["beat365亚洲官方网站"], parentTitle: '新闻资讯' },
                        { title: "通知公告", columnId: vueInstance.$columnIdFile["通知公告"], parentTitle: '新闻资讯' },
                    ],
                    tabs4: [
                        { title: "旅游管理系", columnId: vueInstance.$columnIdFile["旅游管理系"], parentTitle: '师资队伍' },
                        { title: "旅游规划系", columnId: vueInstance.$columnIdFile["旅游规划系"], parentTitle: '师资队伍' },
                        { title: "旅游营销与电子商务系", columnId: vueInstance.$columnIdFile["旅游营销与电子商务系"], parentTitle: '师资队伍' },
                        { title: "酒店管理系", columnId: vueInstance.$columnIdFile["酒店管理系"], parentTitle: '师资队伍' },
                        { title: "健康产业管理系", columnId: vueInstance.$columnIdFile["健康产业管理系"], parentTitle: '师资队伍' },
                        { title: "会展经济与管理系", columnId: vueInstance.$columnIdFile["会展经济与管理系"], parentTitle: '师资队伍' },
                        { title: "客座教授", columnId: vueInstance.$columnIdFile["客座教授"], parentTitle: '师资队伍' },
                        { title: "院行政", columnId: vueInstance.$columnIdFile["院行政"], parentTitle: '师资队伍' },
                    ],
                    tabs5: [
                        { title: "旅游管理专业导师(经济方向)", columnId: vueInstance.$columnIdFile["旅游管理专业导师(经济方向)"], parentTitle: '导师队伍' },
                        { title: "旅游管理专业导师(规划方向)", columnId: vueInstance.$columnIdFile["旅游管理专业导师(规划方向)"], parentTitle: '导师队伍' },
                        { title: "旅游管理专业导师(营销方向)", columnId: vueInstance.$columnIdFile["旅游管理专业导师(营销方向)"], parentTitle: '导师队伍' },
                        { title: "饭店管理专业导师", columnId: vueInstance.$columnIdFile["饭店管理专业导师"], parentTitle: '导师队伍' },
                        { title: "会展管理专业导师", columnId: vueInstance.$columnIdFile["会展管理专业导师"], parentTitle: '导师队伍' },
                    ],
                    tabs6: [
                        { title: "本科生教育", columnId: vueInstance.$columnIdFile["本科生教育"], parentTitle: '教学科研' },
                        { title: "研究生教育", columnId: vueInstance.$columnIdFile["研究生教育"], parentTitle: '教学科研' },
                        { title: "科学研究", columnId: vueInstance.$columnIdFile["科学研究"], parentTitle: '教学科研' },
                        { title: "社会服务", columnId: vueInstance.$columnIdFile["社会服务"], parentTitle: '教学科研' },
                    ],
                    tabs7: [
                        { title: "学生活动", columnId: vueInstance.$columnIdFile["学生活动"], parentTitle: '学工动态' },
                        { title: "学生获奖", columnId: vueInstance.$columnIdFile["学生获奖"], parentTitle: '学工动态' },
                        { title: "企业奖学金", columnId: vueInstance.$columnIdFile["企业奖学金"], parentTitle: '学工动态' },
                    ],
                    tabs8: [
                        { title: "交流项目", columnId: vueInstance.$columnIdFile["交流项目"], parentTitle: '国际合作' },
                        { title: "留学生教育", columnId: vueInstance.$columnIdFile["留学生教育"], parentTitle: '国际合作' },
                    ],
                    tabs9: [
                        { title: "本科生招生", columnId: vueInstance.$columnIdFile["本科生招生"], parentTitle: '招生就业' },
                        { title: "研究生招生", columnId: vueInstance.$columnIdFile["研究生招生"], parentTitle: '招生就业' },
                        { title: "留学生招生", columnId: vueInstance.$columnIdFile["留学生招生"], parentTitle: '招生就业' },
                        { title: "就业指导", columnId: vueInstance.$columnIdFile["就业指导"], parentTitle: '招生就业' },
                        { title: "校友风采", columnId: vueInstance.$columnIdFile["校友风采"], parentTitle: '招生就业' },
                    ],
                    tabs10: [
                        { title: "党建工作", columnId: vueInstance.$columnIdFile["党建工作"], parentTitle: '党建工会' },
                        { title: "工会工作", columnId: vueInstance.$columnIdFile["工会工作"], parentTitle: '党建工会' },
                    ],
                    tabs11: [
                        { title: "表格下载", columnId: vueInstance.$columnIdFile["表格下载"], parentTitle: '表格下载' },
                    ],
                }

            },
            footer: {
                hasLink: true,
                hasQrCode: false,
                bottomArr: [
                    {
                        name: '二外就业网',
                        link: 'http://jiuye.bisu.edu.cn/'
                    },
                    {
                        name: '二外招生网',
                        link: 'https://zs.bisu.edu.cn/'
                    },
                    {
                        name: 'UNWTO',
                        link: 'https://www.unwto.org/'
                    },
                    {
                        name: '中华人民共和国文化和旅游部',
                        link: 'https://www.mct.gov.cn/'
                    },
                ],
                qrCodeArr: []
            }
        },
        // 10个  =================
        yyxy: {
            header: {
                Carousel:true,
                navList:[
                    {
                        id: '1',
                        name: '首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '学院概况',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "学院简介",
                                id: vueInstance.$columnIdFile["学院简介"],
                                type: "detail",
                            },
                            {
                                name: "学院领导",
                                id: vueInstance.$columnIdFile["学院领导"],
                                type: "list",
                            },
                            {
                                name: "机构设置",
                                id: vueInstance.$columnIdFile["机构设置"],
                                type: "detail",
                            },
                            {
                                name: "知名学者",
                                id: vueInstance.$columnIdFile["知名学者"],
                                type: "list",
                            },
                            {
                                name: "联系学院",
                                id: vueInstance.$columnIdFile["联系学院"],
                                type: "detail",
                            },
                        ],
                    },
                    {
                        id: '3',
                        name: '新闻公告',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "新闻资讯",
                                id: vueInstance.$columnIdFile["新闻资讯"],
                                type: "list",
                            },
                            {
                                name: "通知公告",
                                id: vueInstance.$columnIdFile["通知公告"],
                                type: "list",
                            },
                            {
                                name: "媒体英院",
                                id: vueInstance.$columnIdFile["媒体英院"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '4',
                        name: '党群工作',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "党建工作",
                                id: vueInstance.$columnIdFile["党建工作"],
                                type: "list",
                            },
                            {
                                name: "工会工作",
                                id: vueInstance.$columnIdFile["工会工作"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '5',
                        name: '师资队伍',
                        // link: '/teachers',
                        children: [
                            { name: "语言学系", id: vueInstance.$columnIdFile["语言学系"], type: "teachers" },
                            { name: "文学系", id: vueInstance.$columnIdFile["文学系"], type: "teachers" },
                            { name: "翻译系", id: vueInstance.$columnIdFile["翻译系"], type: "teachers" },
                            { name: "跨文化系", id: vueInstance.$columnIdFile["跨文化系"], type: "teachers" },
                            { name: "商务英语系", id: vueInstance.$columnIdFile["商务英语系"], type: "teachers" },
                            { name: "英语教育系", id: vueInstance.$columnIdFile["英语教育系"], type: "teachers" },
                            { name: "国别与区域研究系", id: vueInstance.$columnIdFile["国别与区域研究系"], type: "teachers" },
                            { name: "行政人员", id: vueInstance.$columnIdFile["行政人员"], type: "teachers" },
                        ]
                    },
                    {
                        id: '6',
                        name: '人才培养',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "本科生教育",
                                id: vueInstance.$columnIdFile["本科生教育"],
                                type: "list",
                            },
                            {
                                name: "大学英语教育",
                                id: vueInstance.$columnIdFile["大学英语教育"],
                                type: "list",
                            },
                            {
                                name: "研究生教育",
                                id: vueInstance.$columnIdFile["研究生教育"],
                                type: "list",
                            },
                            {
                                name: "继续教育",
                                id: vueInstance.$columnIdFile["继续教育"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '7',
                        name: '教学科研',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "科教动态",
                                id: vueInstance.$columnIdFile["科教动态"],
                                type: "list",
                            },
                            {
                                name: "本科英语评估",
                                id: vueInstance.$columnIdFile["本科英语评估"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '8',
                        name: '国际交流',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "合作院校",
                                id: vueInstance.$columnIdFile["合作院校"],
                                type: "list",
                            },
                            {
                                name: "交流活动",
                                id: vueInstance.$columnIdFile["交流活动"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '9',
                        name: '学生工作',
                        children: [
                            {
                                name: "学生风采",
                                id: vueInstance.$columnIdFile["学生风采"],
                                type: "list",
                            },
                            {
                                name: "就业实习",
                                id: vueInstance.$columnIdFile["就业实习"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '10',
                        name: '下载专区',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['下载专区'],
                                title: "下载专区",
                                tab: '10',
                                hide: 'hide'
                            },
                        },
                    },
                ]
            },
            content: {
                homeArr: [
                    {
                        title: '新闻资讯',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["新闻资讯"]
                    },
                    {
                        title: '通知公告',
                        is: 'WgNoticeTwo',
                        id: vueInstance.$columnIdFile["通知公告"]
                    },
                    {
                        title: '学工动态',
                        is: 'WgPerson',
                        id: vueInstance.$columnIdFile["学工动态"]
                    },
                    {
                        title: '教学科研',
                        is: 'WgNotice',
                        id: vueInstance.$columnIdFile["教学科研"]
                    },
                ],
                tabs: {

                }

            },
            footer: {
                hasLink: true,
                hasQrCode: false,
                bottomArr: [
                    {
                        name: '教育部',
                        link: 'http://www.moe.gov.cn/'
                    },{
                        name: '北京高校大学英语教育发展中心',
                        link: 'http://cedc.bisu.edu.cn/bisucolen/index.php'
                    },
                    {
                        name: '丹麦研究中心',
                        link: 'http://danmai.bisu.edu.cn/'
                    },
                    {
                        name: '联合国教科文组织研究中心',
                        link: 'http://jkw.bisu.edu.cn/'
                    }
                ],
                qrCodeArr: []
            }
        },
        jx60zn: {
            header: {
                Carousel:true,
                navList:[
                    {
                        id: '1',
                        name: '首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '活动公告',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['活动公告'],
                                title: "活动公告",
                                tab: '2',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '3',
                        name: '校友祝福',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['校友祝福'],
                                title: "校友祝福",
                                tab: '3',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '4',
                        name: '光影二外',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['光影二外'],
                                title: "光影二外",
                                tab: '4',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '5',
                        name: '校史长廊',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['校史长廊'],
                                title: "校史长廊",
                                tab: '5',
                                hide: 'hide'
                            },
                        },
                    },
                ]
            },
            content: {
                homeArr: [
                    {
                        title: '活动公告',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["活动公告"]
                    },
                    {
                        title: '校友祝福',
                        is: 'WgNoticeTwo',
                        id: vueInstance.$columnIdFile["校友祝福"]
                    },
                    {
                        title: '光影二外',
                        is: 'WgPerson',
                        id: vueInstance.$columnIdFile["光影二外"]
                    },
                    {
                        title: '校史长廊',
                        is: 'WgHistory',
                        id: vueInstance.$columnIdFile["校史长廊"]
                    },
                ],
                tabs: {

                }

            },
            footer: {
                hasLink: false,
                hasQrCode: false,
                bottomArr: [],
                qrCodeArr: []
            }
        },
        MIB: {
            header: {
                Carousel:true,
                navList:[
                    {
                        id: '1',
                        name: '首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '项目简介',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "项目概况",
                                id: vueInstance.$columnIdFile["项目概况"],
                                type: "detail",
                            },
                            {
                                name: "项目特色",
                                id: vueInstance.$columnIdFile["项目特色"],
                                type: "detail",
                            },
                            {
                                name: "课程设置",
                                id: vueInstance.$columnIdFile["课程设置"],
                                type: "detail",
                            },
                        ],
                    },
                    {
                        id: '3',
                        name: '招生就业',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "招生专栏",
                                id: vueInstance.$columnIdFile["招生专栏"],
                                type: "list",
                            },
                            {
                                name: "就业信息",
                                id: vueInstance.$columnIdFile["就业信息"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '4',
                        name: '师资一览',
                        // link: '/teachers',
                        children: [
                            { name: "校内导师", id: vueInstance.$columnIdFile["校内导师"], type: "teachers" },
                            { name: "产业导师", id: vueInstance.$columnIdFile["产业导师"], type: "teachers" },
                        ]
                    },
                    {
                        id: '5',
                        name: '国际交流',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "合作项目",
                                id: vueInstance.$columnIdFile["合作项目"],
                                type: "list",
                            },
                            {
                                name: "交流动态",
                                id: vueInstance.$columnIdFile["交流动态"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '6',
                        name: '实习实践',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "实习动态",
                                id: vueInstance.$columnIdFile["实习动态"],
                                type: "list",
                            },
                            {
                                name: "实践基地",
                                id: vueInstance.$columnIdFile["实践基地"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '7',
                        name: '多彩生活',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "培养理念",
                                id: vueInstance.$columnIdFile["培养理念"],
                                type: "list",
                            },
                            {
                                name: "组织建设",
                                id: vueInstance.$columnIdFile["组织建设"],
                                type: "list",
                            },
                            {
                                name: "魅力活动",
                                id: vueInstance.$columnIdFile["魅力活动"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '8',
                        name: '品牌塑造',
                        children: [
                            {
                                name: "商务讲堂",
                                id: vueInstance.$columnIdFile["商务讲堂"],
                                type: "list",
                            },
                            {
                                name: "商务通讯",
                                id: vueInstance.$columnIdFile["商务通讯"],
                                type: "list",
                            },
                            {
                                name: "Seminar",
                                id: vueInstance.$columnIdFile["Seminar"],
                                type: "list",
                            },
                        ]
                    },
                    {
                        id: '9',
                        name: '资料下载',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['资料下载'],
                                title: "资料下载",
                                tab: '9',
                                hide: 'hide'
                            },
                        },
                    },
                ]
            },
            content: {
                homeArr: [
                    {
                        title: '新闻资讯',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["新闻资讯"]
                    },
                    {
                        title: '通知公告',
                        is: 'WgNoticeTwo',
                        id: vueInstance.$columnIdFile["通知公告"]
                    },
                    {
                        title: '招生专栏',
                        is: 'WgPerson',
                        id: vueInstance.$columnIdFile["招生专栏"]
                    },
                    // {
                    //     title: '教学科研',
                    //     is: 'WgScienceTwo',
                    //     id: vueInstance.$columnIdFile["教学科研"]
                    // },
                ],
                tabs: {

                }

            },
            footer: {
                hasLink: false,
                hasQrCode: false,
                bottomArr: [],
                qrCodeArr: []
            }
        },
        jckxb: {
            header: {
                Carousel:true,
                navList:[
                    {
                        id: '1',
                        name: '首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '学院概况',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "学院简介",
                                id: vueInstance.$columnIdFile["学院简介"],
                                type: "detail",
                            },
                            {
                                name: "现任领导",
                                id: vueInstance.$columnIdFile["现任领导"],
                                type: "detail",
                            },
                            {
                                name: "联系我们",
                                id: vueInstance.$columnIdFile["联系我们"],
                                type: "detail",
                            },
                        ],
                    },
                    {
                        id: '3',
                        name: '师资队伍',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "计算机教学部",
                                id: vueInstance.$columnIdFile["计算机教学部"],
                                type: "teachers",
                            },
                            {
                                name: "数学教学部",
                                id: vueInstance.$columnIdFile["数学教学部"],
                                type: "teachers",
                            },
                            {
                                name: "法学教学部",
                                id: vueInstance.$columnIdFile["法学教学部"],
                                type: "teachers",
                            },
                            {
                                name: "通识素质与德育教学部",
                                id: vueInstance.$columnIdFile["通识素质与德育教学部"],
                                type: "teachers",
                            },
                        ],
                    },
                    {
                        id: '4',
                        name: '学术研究',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "科研项目",
                                id: vueInstance.$columnIdFile["科研项目"],
                                type: "list",
                            },
                            {
                                name: "科研成果",
                                id: vueInstance.$columnIdFile["科研成果"],
                                type: "list",
                            },
                            {
                                name: "学术交流",
                                id: vueInstance.$columnIdFile["学术交流"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '5',
                        name: '人才培养',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['人才培养'],
                                title: "人才培养",
                                tab: '5',
                                hide: 'hide'
                            },
                        },
                    },
                ]
            },
            content: {
                homeArr: [
                    {
                        title: '综合新闻',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["综合新闻"]
                    },
                    {
                        title: '学院公告',
                        is: 'WgNotice',
                        id: vueInstance.$columnIdFile["学院公告"]
                    },
                    {
                        title: '党建工作',
                        is: 'WgList',
                        id: vueInstance.$columnIdFile["党建工作"]
                    },
                    {
                        title: '学科竞赛',
                        is: 'WgPerson',
                        id: vueInstance.$columnIdFile["学科竞赛"]
                    },
                ],
                tabs: {

                }

            },
            footer: {
                hasLink: false,
                hasQrCode: false,
                bottomArr: [],
                qrCodeArr: []
            }
        },
        tyb: {
            header: {
                Carousel:true,
                navList:[
                    {
                        id: '1',
                        name: '首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '部门简介',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "基本情况",
                                id: vueInstance.$columnIdFile["基本情况"],
                                type: "detail",
                            },
                            {
                                name: "组织机构",
                                id: vueInstance.$columnIdFile["组织机构"],
                                type: "detail",
                            },
                        ],
                    },
                    {
                        id: '3',
                        name: 'beat365亚洲官方网站',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['beat365亚洲官方网站'],
                                title: "beat365亚洲官方网站",
                                tab: '3',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '4',
                        name: '部门服务',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "通知公告",
                                id: vueInstance.$columnIdFile["通知公告"],
                                type: "list",
                            },
                            {
                                name: "表格下载",
                                id: vueInstance.$columnIdFile["表格下载"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '5',
                        name: '师资队伍',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "专任教师",
                                id: vueInstance.$columnIdFile["专任教师"],
                                type: "teachers",
                            },
                            {
                                name: "行政人员",
                                id: vueInstance.$columnIdFile["行政人员"],
                                type: "teachers",
                            },
                        ],
                    },
                    {
                        id: '6',
                        name: '体质健康测试',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['体质健康测试'],
                                title: "体质健康测试",
                                tab: '6',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '7',
                        name: '课程资源',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "课程介绍",
                                id: vueInstance.$columnIdFile["课程介绍"],
                                type: "list",
                            },
                            {
                                name: "课程资源",
                                id: vueInstance.$columnIdFile["课程资源"],
                                type: "list",
                            },
                        ],
                    },

                ]
            },
            content: {
                homeArr: [
                    {
                        title: 'beat365亚洲官方网站',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["beat365亚洲官方网站"]
                    },
                    {
                        title: ['体质健康测试','表格下载'],
                        is: 'WgMediaDoubleRow',
                        maxLength: 3,
                        id: [vueInstance.$columnIdFile["体质健康测试"],vueInstance.$columnIdFile["表格下载"]]
                    },
                    // {
                    //     title: '表格下载',
                    //     is: 'WgMedia',
                    //     id: vueInstance.$columnIdFile["表格下载"]
                    // },
                ],
                tabs: {
                    tabs1: [
                        { title: "beat365亚洲官方网站", columnId: vueInstance.$columnIdFile["beat365亚洲官方网站"], parentTitle: '新闻资讯' },
                        { title: "体质健康测试", columnId: vueInstance.$columnIdFile["体质健康测试"], parentTitle: '体质健康测试' },
                        { title: "表格下载", columnId: vueInstance.$columnIdFile["表格下载"], parentTitle: '表格下载' },
                       
                    ],
                }

            },
            footer: {
                hasLink: true,
                hasQrCode: true,
                bottomArr: [],
                qrCodeArr:  ['weixin']
            }
        },
        sxy: {
            header: {
                Carousel:true,
                navList:[
                    {
                        id: '1',
                        name: '首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '关于学院',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "学院简介",
                                id: vueInstance.$columnIdFile["学院简介"],
                                type: "detail",
                            },
                            {
                                name: "领导班子",
                                id: vueInstance.$columnIdFile["领导班子"],
                                type: "detail",
                            },
                            {
                                name: "组织机构",
                                id: vueInstance.$columnIdFile["组织机构"],
                                type: "detail",
                            },
                            {
                                name: "院长寄语",
                                id: vueInstance.$columnIdFile["院长寄语"],
                                type: "list",
                            },
                            {
                                name: "联系我们",
                                id: vueInstance.$columnIdFile["联系我们"],
                                type: "detail",
                            },
                        ],
                    },
                    {
                        id: '3',
                        name: '新闻公告',
                        children: [
                            {
                                name: "通知公告",
                                id: vueInstance.$columnIdFile["通知公告"],
                                type: "list",
                            },
                            {
                                name: "工作纵览",
                                id: vueInstance.$columnIdFile["工作纵览"],
                                type: "list",
                            },
                            {
                                name: "媒体商院",
                                id: vueInstance.$columnIdFile["媒体商院"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '4',
                        name: '教授研究',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "科研动态",
                                id: vueInstance.$columnIdFile["科研动态"],
                                type: "list",
                            },
                            {
                                name: "讲座信息",
                                id: vueInstance.$columnIdFile["讲座信息"],
                                type: "list",
                            },
                            {
                                name: "学科建设",
                                id: vueInstance.$columnIdFile["学科建设"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '5',
                        name: '师资风采',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "财务管理",
                                id: vueInstance.$columnIdFile["财务管理"],
                                type: "teachers",
                            },
                            {
                                name: "市场营销",
                                id: vueInstance.$columnIdFile["市场营销"],
                                type: "teachers",
                            },
                        ],
                    },
                    {
                        id: '6',
                        name: '教学项目',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "本科教学",
                                id: vueInstance.$columnIdFile["本科教学"],
                                type: "list",
                            },
                            {
                                name: "学术硕士",
                                id: vueInstance.$columnIdFile["学术硕士"],
                                type: "list",
                            },
                            {
                                name: "MPAcc",
                                id: vueInstance.$columnIdFile["MPAcc"],
                                type: "list",
                            },
                            {
                                name: "MBA",
                                id: vueInstance.$columnIdFile["MBA"],
                                type: "list",
                            },

                        ],
                    },
                    {
                        id: '7',
                        name: 'Study in China',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "国际本科",
                                id: vueInstance.$columnIdFile["国际本科"],
                                type: "list",
                            },
                            {
                                name: "国际硕士",
                                id: vueInstance.$columnIdFile["国际硕士"],
                                type: "list",
                            },
                            {
                                name: "IMPAcc",
                                id: vueInstance.$columnIdFile["IMPAcc"],
                                type: "list",
                            },
                            {
                                name: "IMBA",
                                id: vueInstance.$columnIdFile["IMBA"],
                                type: "list",
                            },
                            {
                                name: "短期培训",
                                id: vueInstance.$columnIdFile["短期培训"],
                                type: "list",
                            },

                        ],
                    },
                    {
                        id: '8',
                        name: '国际交流',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "国际项目",
                                id: vueInstance.$columnIdFile["国际项目"],
                                type: "list",
                            },
                            {
                                name: "学术交流",
                                id: vueInstance.$columnIdFile["学术交流"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '9',
                        name: '党建思政',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "党建工作",
                                id: vueInstance.$columnIdFile["党建工作"],
                                type: "list",
                            },
                            {
                                name: "工会工作",
                                id: vueInstance.$columnIdFile["工会工作"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '10',
                        name: '学生工作',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "学生事务",
                                id: vueInstance.$columnIdFile["学生事务"],
                                type: "list",
                            },
                            {
                                name: "学生动态",
                                id: vueInstance.$columnIdFile["学生动态"],
                                type: "list",
                            },
                            {
                                name: "表格下载",
                                id: vueInstance.$columnIdFile["表格下载"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '11',
                        name: '学院资源',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "精品课程",
                                id: vueInstance.$columnIdFile["精品课程"],
                                type: "list",
                            },
                            {
                                name: "制度文件",
                                id: vueInstance.$columnIdFile["制度文件"],
                                type: "list",
                            },
                        ],
                    },
                ]
            },
            content: {
                homeArr: [
                    {
                        title: '工作纵览',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["工作纵览"]
                    },
                    {
                        title: '教授研究',
                        is: 'WgMedia',
                        id: vueInstance.$columnIdFile["教授研究"]
                    },
                    {
                        title: '教学项目',
                        is: 'WgSxyScienceTow',
                        id: vueInstance.$columnIdFile["教学项目"]
                    },
                    {
                        title: '国际交流',
                        is: 'WgTrade',
                        id: vueInstance.$columnIdFile["国际交流"]
                    },
                    {
                        title: '党建思政',
                        is: 'WgList',
                        id: vueInstance.$columnIdFile["党建思政"]
                    },
                    // {
                    //     title: '通知公告',
                    //     is: 'WgMedia',
                    //     id: vueInstance.$columnIdFile["通知公告"]
                    // },
                    {
                        title: ['通知公告','媒体商院'],
                        is: 'WgMediaDoubleRow',
                        maxLength: 3,
                        id: [vueInstance.$columnIdFile["通知公告"],vueInstance.$columnIdFile["媒体商院"]]
                    },
                    // {
                    //     title: '媒体商院',
                    //     is: 'WgMedia',
                    //     id: vueInstance.$columnIdFile["媒体商院"]
                    // },
                ],
                tabs: {
                    tabs1: [
                        { title: "工作纵览", columnId: vueInstance.$columnIdFile["工作纵览"], parentTitle: '工作纵览' },
                        { title: "教授研究", columnId: vueInstance.$columnIdFile["教授研究"], parentTitle: '教授研究' },
                        { title: "教学项目", columnId: vueInstance.$columnIdFile["教学项目"], parentTitle: '表格下载' },
                        { title: "国际交流", columnId: vueInstance.$columnIdFile["国际交流"], parentTitle: '国际交流' },
                        { title: "党建思政", columnId: vueInstance.$columnIdFile["党建思政"], parentTitle: '党建思政' },
                        { title: "通知公告", columnId: vueInstance.$columnIdFile["通知公告"], parentTitle: '通知公告' },
                        { title: "媒体商院", columnId: vueInstance.$columnIdFile["媒体商院"], parentTitle: '媒体商院' },

                       
                    ],
                    tabs2:[
                        { title: "本科教学", columnId: vueInstance.$columnIdFile["本科教学"], parentTitle: '本科教学' },
                        { title: "学术硕士", columnId: vueInstance.$columnIdFile["学术硕士"], parentTitle: '学术硕士' },
                        { title: "MPAcc", columnId: vueInstance.$columnIdFile["MPAcc"], parentTitle: 'MPAcc' },
                        { title: "MBA", columnId: vueInstance.$columnIdFile["MBA"], parentTitle: 'MBA' },
                    ]
                }

            },
            footer: {
                hasLink: false,
                hasQrCode: false,
                bottomArr: [],
                qrCodeArr:  []
            }
        },
        gjfyxy: {
            header: {
                Carousel:true,
                navList:[
                    {
                        id: '1',
                        name: '首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '学院概况',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "学院简介",
                                id: vueInstance.$columnIdFile["学院简介"],
                                type: "detail",
                            },
                            {
                                name: "联系我们",
                                id: vueInstance.$columnIdFile["联系我们"],
                                type: "detail",
                            },
                        ],
                    },
                    {
                        id: '3',
                        name: '师资队伍',
                        children: [
                            {
                                name: "教授",
                                id: vueInstance.$columnIdFile["教授"],
                                type: "teachers",
                            },
                            {
                                name: "副教授",
                                id: vueInstance.$columnIdFile["副教授"],
                                type: "teachers",
                            },
                            {
                                name: "讲师",
                                id: vueInstance.$columnIdFile["讲师"],
                                type: "teachers",
                            },
                            {
                                name: "助教",
                                id: vueInstance.$columnIdFile["助教"],
                                type: "teachers",
                            },
                            {
                                name: "管理团队",
                                id: vueInstance.$columnIdFile["管理团队"],
                                type: "teachers",
                            },
                            {
                                name: "客座教授",
                                id: vueInstance.$columnIdFile["客座教授"],
                                type: "teachers",
                            },
                            {
                                name: "外籍专家",
                                id: vueInstance.$columnIdFile["外籍专家"],
                                type: "teachers",
                            },
                        ],
                    },
                    {
                        id: '4',
                        name: '人才培养',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['人才培养'],
                                title: "人才培养",
                                tab: '4',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '5',
                        name: '教学科研',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['教学科研'],
                                title: "教学科研",
                                tab: '5',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '6',
                        name: '招生就业',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "招生信息",
                                id: vueInstance.$columnIdFile["招生信息"],
                                type: "list",
                            },
                            {
                                name: "就业展望",
                                id: vueInstance.$columnIdFile["就业展望"],
                                type: "list",
                            },
                            {
                                name: "实习实践基地",
                                id: vueInstance.$columnIdFile["实习实践基地"],
                                type: "list",
                            },
                            {
                                name: "非学历教育",
                                id: vueInstance.$columnIdFile["非学历教育"],
                                type: "list",
                            },

                        ],
                    },
                    {
                        id: '7',
                        name: '国际交流',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "交流项目",
                                id: vueInstance.$columnIdFile["交流项目"],
                                type: "list",
                            },
                            {
                                name: "合作院校",
                                id: vueInstance.$columnIdFile["合作院校"],
                                type: "list",
                            }

                        ],
                    },
                    {
                        id: '8',
                        name: '学生事务',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "学风建设",
                                id: vueInstance.$columnIdFile["学风建设"],
                                type: "list",
                            },
                            {
                                name: "学生活动",
                                id: vueInstance.$columnIdFile["学生活动"],
                                type: "list",
                            },
                            {
                                name: "社会实践",
                                id: vueInstance.$columnIdFile["社会实践"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '9',
                        name: '党建思政',
                        type: 'detail',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['党建思政'],
                                title: "党建思政",
                                tab: '9',
                                hide: 'hide'
                            },
                        },
                    },
                    {
                        id: '10',
                        name: '友情链接',
                        type: 'detail',
                        // link: `/newsDetail/${vueInstance.$columnIdFile['学院概况']}/list`,
                        children: [
                            {
                                name: "国际组织",
                                id: vueInstance.$columnIdFile["国际组织"],
                                type: "list",
                            },
                            {
                                name: "政府机关",
                                id: vueInstance.$columnIdFile["政府机关"],
                                type: "list",
                            },
                            {
                                name: "学生团体",
                                id: vueInstance.$columnIdFile["学生团体"],
                                type: "list",
                            },
                            {
                                name: "兄弟院校",
                                id: vueInstance.$columnIdFile["兄弟院校"],
                                type: "list",
                            },
                        ],
                    },
                    {
                        id: '11',
                        name: '多语实训平台',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['多语实训平台'],
                                title: "多语实训平台",
                                tab: '11',
                                hide: 'hide'
                            },
                        },
                    },
                ]
            },
            content: {
                homeArr: [
                    {
                        title: 'beat365亚洲官方网站',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["beat365亚洲官方网站"]
                    },
                    {
                        title: '通知公告',
                        is: 'WgNoticeTwo',
                        id: vueInstance.$columnIdFile["通知公告"]
                    },
                    {
                        title: '党建思政',
                        is: 'WgList',
                        id: vueInstance.$columnIdFile["党建思政"]
                    },
                    {
                        title: '教学科研',
                        is: 'WgPerson',
                        id: vueInstance.$columnIdFile["教学科研"]
                    },
                  
                    {
                        title: '学工动态',
                        is: 'WgTrade',
                        id: vueInstance.$columnIdFile["学工动态"]
                    },
                    {
                        title: '实习实践',
                        is: 'WgScienceTwo',
                        id: vueInstance.$columnIdFile["实习实践"]
                    },
                    {
                        title: '高翻相册',
                        is: 'WgSwiper',
                        id: vueInstance.$columnIdFile["高翻相册"]
                    },
                ],
                tabs: {

                }

            },
            footer: {
                hasLink: true,
                hasQrCode: false,
                bottomArr: [
                    {
                        name: '上海合作组织',
                        link: 'https://eng.sectsco.org/'
                    },{
                        name: '中国外交部',
                        link: 'https://www.fmprc.gov.cn/mfa_eng/'
                    },
                    {
                        name: '中国外文局',
                        link: 'http://www.cicgcorp.com/index.html'
                    }
                ],
                qrCodeArr:  []
            }
        },
        d9cdydbdh:{
            header:{
                Carousel:false,
                navList:[
                    {
                        id: '1',
                        name: '专题首页',
                        title: 'HOME',
                        link: '/home',
                        children: [],
                    },
                    {
                        id: '2',
                        name: '聚焦发展',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['聚焦发展'],
                                title: "聚焦发展",
                                hide: false
                            },
                        },
                    },
                    {
                        id: '3',
                        name: '党代表心声',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['党代表心声'],
                                title: "党代表心声",
                                hide: 'fasle'
                            },
                        },
                    },
                    {
                        id: '4',
                        name: '五年回眸',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['五年回眸'],
                                title: "五年回眸",
                                hide: 'fasle'
                            },
                        },
                    },
                    {
                        id: '5',
                        name: '党建知识',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['党建知识'],
                                title: "党建知识",
                                hide: 'fasle'
                            },
                        },
                    },
                    {
                        id: '6',
                        name: '图说党建',
                        link: {
                            path: "/newsTrends",
                            query: {
                                columnId: vueInstance.$columnIdFile['图说党建'],
                                title: "图说党建",
                                hide: 'false'
                            },
                        },
                    },
                 
                ]  
            },
            content: {
                homeArr: [
                    {
                        title: '轮播图',
                        is: 'WgFadeSwiper',
                        id: vueInstance.$columnIdFile["轮播图"]
                    },
                    {
                        title: '聚焦发展',
                        is: 'WgNews',
                        id: vueInstance.$columnIdFile["聚焦发展"]
                    },
                    {
                        title: ['党代表心声','五年回眸','党建知识'],
                        is: 'WgMediaThreeRow',
                        maxLength: 5,
                        id: [vueInstance.$columnIdFile["党代表心声"],vueInstance.$columnIdFile["五年回眸"],vueInstance.$columnIdFile["党建知识"]]
                    },
                    {
                        title: '图说党建',
                        is: 'WgSwiper',
                        id: vueInstance.$columnIdFile["图说党建"]
                    },
                ],
                tabs: {
                    tabs1: [
                        { title: "聚焦发展", columnId: vueInstance.$columnIdFile["聚焦发展"], parentTitle: '聚焦发展' },
                        { title: "党代表心声", columnId: vueInstance.$columnIdFile["党代表心声"], parentTitle: '党代表心声' },
                        { title: "五年回眸", columnId: vueInstance.$columnIdFile["五年回眸"], parentTitle: '五年回眸' },
                        { title: "党建知识", columnId: vueInstance.$columnIdFile["党建知识"], parentTitle: '党建知识' },
                        { title: "图说党建", columnId: vueInstance.$columnIdFile["图说党建"], parentTitle: '图说党建' }

                    ],
                }
            },
            footer: {}
        }
     
    }

}
