<template>
  <div id="app">
<!--    <Header v-if="!isPhone"></Header>-->
<!--    <PhoneHeader v-else></PhoneHeader>-->
<!--    <router-view v-if="isRouterAlive" />-->
<!--    <Footer v-if="!isPhone"></Footer>-->
<!--    <PhoneFooter v-else></PhoneFooter>-->
    <Header v-if="this.college !== 'd9cdydbdh' || this.$route.path !=='/newsTrends'"></Header>
    <router-view v-if="isRouterAlive" />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import PhoneHeader from "./components/Phone/PhoneHeader.vue";
import PhoneFooter from "./components/Phone/PhoneFooter.vue";
import { allCollegeInfo } from './allCollegeInfo'
export default {
  components: {
    Header,
    Footer,
    // PhoneHeader,
    // PhoneFooter,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  created() {
    const college = process.env.VUE_APP_COLLEGE;
    this.college = college;
    this.$store.commit('setCollege', college);
    document.title = this.$store.state.str2name[college];
    if (college === 'zdxy') {
      document.title = '中东学院';
    }
    if (college === 'yyxy') {
      document.title = '英语学院'
    }

    if (college && college !== 'whycbxy') {
      const pageJson = allCollegeInfo(this)[college];
      if (Object.keys(pageJson.content.tabs).length === 0) {
        pageJson.header.navList.forEach(item => {
          pageJson.content.tabs['tabs' + item.id] = [];
          if (item.children && item.children.length) {
            item.children.filter(child => child.type === 'list').forEach(child => {
              pageJson.content.tabs['tabs' + item.id].push({ title: child.name, columnId: this.$columnIdFile[child.name], parentTitle: item.name })
            });
          } else {
            pageJson.content.tabs['tabs' + item.id].push({ title: item.name, columnId: this.$columnIdFile[item.name], parentTitle: item.name })
          }
        })
        pageJson.content.tabs.tabs1 = pageJson.content.homeArr.map(item => {
          return  { title: item.title, columnId: this.$columnIdFile[item.title], parentTitle: '新闻资讯' }
        })
      }
      this.$store.commit('setPageJson', pageJson)
      this.pageJson = this.$store.state.pageJson;
    }
  },
  data() {
    return {
      isRouterAlive: true,
      pageJson: {},
      college:'',
      
    };
  },
  computed: {
    isPhone() {
      const userAgent = navigator.userAgent;
      const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
      return isMobile;
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss">
@import "./assets/css/common";
//::-webkit-scrollbar {
//  position: absolute;
//  width: 6px;
//  height: 6px;
//  margin-left: -10px;
//}
//::-webkit-scrollbar-thumb {
//  cursor: pointer;
//  background-color: #f0f1f5;
//  background-clip: content-box;
//  border-color: transparent;
//  border-style: solid;
//  border-width: 1px 2px;
//  border-radius: 7px;
//}
//::-webkit-scrollbar-track {
//  background-color: transparent;
//  border-right: none;
//  border-left: none;
//}

img {
  max-width: 100% !important;
}
video {
  max-width: 100% !important;
}
body {
  font-family: "Noto Sans SC", "微软雅黑", "黑体" !important;
}
</style>
